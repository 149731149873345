
import { Alert, Button, Card, Divider, Stack } from '@mui/material';
import { Box, List, ListItem, ListItemButton } from '@mui/material';
import React, { useEffect } from 'react';
import General from './settings/General.tsx';
import CustomFields from './settings/Fields.tsx';
import Registration from './settings/Registration.tsx';
import Terms from './settings/Terms.tsx';
import { useSelector, useDispatch } from 'react-redux';
import { setTenantInfo } from '../features/app.js';
import jax from '../helper/jax.js';
import Roles from './Roles.tsx';

const Settings: React.FC = () => {

	const [section, setSection] = React.useState<string>('General Settings');

	const [dirtySettings, setDirtySettings] = React.useState<any>({});
	const [dirtyTerms, setDirtyTerms] = React.useState<any>({});
	const [isDirty, setIsDirty] = React.useState<boolean>(false);

	const {tenantInfo} = useSelector((state: any) => state.app);
	const [tenant, setTenant] = React.useState<any>(tenantInfo);

	const dispatch = useDispatch();
	
	const sections = {
		"General Settings": <General/>,
		"User Roles": <Roles/>,
		"User Registration": <Registration/>,
		"Custom Fields": <CustomFields/>,
		"Terminology": <Terms/>,
	}

	const onSettingChange = (key, value) => {
		//setTenant({...tenant, settings: { ...tenant.settings, [key]: value}});
		setDirtySettings({...dirtySettings, [key]: value});
	}

	const onTermChange = (key, subkey, value) => {
		let terms = {...tenant.terms, [key]: {...tenant.terms[key], [subkey]:value}};
		setTenant({...tenant, terms});
		setDirtyTerms({...dirtyTerms, [key]: {...tenant.terms[key], [subkey]:value}});
	}

	useEffect(() => {
		setIsDirty(Object.keys(dirtySettings).length > 0 || Object.keys(dirtyTerms).length > 0);
	}, [dirtySettings, dirtyTerms]);

	useEffect(() => {
		setTenant({...tenantInfo});
	}, [tenantInfo]);

	const save = async () => {
		// save dirty settings
		await jax.post('/app/admin/settings', {settings: dirtySettings, terms: dirtyTerms});
		dispatch(setTenantInfo({
			...tenant,
			settings: {...tenant.settings, ...dirtySettings},
			terms: {...tenant.terms, ...dirtyTerms}
		}));
		setDirtySettings({});
		setDirtyTerms({});
	}

	const revert = () => {
		setTenant({...tenantInfo});
		setDirtySettings({});
		setDirtyTerms({});
	}

	return (
		<Stack> 
			<Stack direction="row" justifyContent="space-between" alignItems="center" ml={2} mr={1}>
				<Box my={2}><h2>System Settings</h2></Box>
				{isDirty && <Alert severity="warning" icon={false} action={<Stack direction="row" spacing={1}>
							<Button size="small" variant="contained" color="warning" onClick={save}>Save Changes</Button>
							<Button size="small" variant="outlined" color="warning" onClick={revert}>Discard Changes</Button>
						</Stack>}>
					<Stack direction="row" alignItems="center" spacing={2}>
						<Box>Your changes have not yet been saved</Box>
					</Stack>
				</Alert>}
			</Stack>
			<Divider/>
			<Stack direction="row" spacing={2} m={2} alignItems="flex-start">
				<Card variant="outlined">
					<Box minWidth={300}>
						<List dense>
							{Object.keys(sections).map((s, i) => {
								return <ListItemButton  divider key={i} onClick={()=>setSection(s)} sx={{fontSize:'0.9rem'}} selected={s == section}>
									<ListItem disableGutters sx={{fontWeight: s == section ? "bold" :""}}>{s}</ListItem>
								</ListItemButton>
							})}
						</List>
					</Box>
				</Card>
				<Divider orientation='vertical' flexItem/>
				<Stack flex={1}>
					{Object.keys(sections).map((s, i) => {
						return React.createElement(sections[s].type, {onSettingChange: onSettingChange, onTermChange: onTermChange, tenantInfo: tenant, show: section == s});
					})}
					
				</Stack>
			</Stack>
		</Stack>
	);
};

export default Settings;