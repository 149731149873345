import { useEffect, useState } from "react";
import jax from "../../helper/jax";
import { useParams } from "react-router-dom";
import Eval from "./Eval";
import { Card, Stack } from "@mui/material";


export default function SubmitEval(props) {
	
	const params = useParams();
	const [schema, setSchema] = useState(null);

	useEffect(()=>{
		jax.get(`/app/evaluations/${params.schema}`).then((res)=>{
			setSchema(res.schema);
		}).catch((err)=>{

		});
	}, []);
			
	return <Stack p={{lg:4, xs:0}} alignItems="stretch">
		<Card >
			<Eval  course_code={params.course_code || '-'} embedded={true} schema={schema} show={!!schema} maxBodyHeight={800}/> 
		</Card>
	</Stack>;
}