import { Link as RouterLink } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel';
import { hasRole, roles, roleNames, can, resources, actions } from "../../features/access";
import jax from "../../helper/jax";
import { Tabs, Tab, Box, Card, Typography, Stack, Paper, useMediaQuery, useTheme, Divider, alpha, MobileStepper, Button, Checkbox, FormControlLabel, Breadcrumbs, Rating, Fab} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Area, Bar, BarChart, CartesianGrid, Cell, ComposedChart, LabelList, Legend, Pie, PieChart, Rectangle, ReferenceLine, ResponsiveContainer, Sector, Text, Tooltip, XAxis, YAxis } from "recharts";
import { ArrowBackIos, ArrowForwardIos, Assistant, AutoAwesome, ChatOutlined, ContentPasteOffOutlined, Group, KeyboardArrowDown, KeyboardArrowUp, Lock, Refresh, ThumbDownAlt, ThumbUpAlt, TipsAndUpdatesOutlined } from "@mui/icons-material";
import {augmentColor, createTheme, darken, lighten, styled} from '@mui/material/styles';
import {standardDeviation, standardNormalTable, variance, zScore} from 'simple-statistics';
import Carousel from "../../components/Carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';

const colors = ['#6D7A2E','#809a9d','#d74242','#ED6C02','#D3C612','#FFD54D','#2c81d6'];



let pTheme = createTheme();
const palette = {
	bg: makeShades('#556870'),
	primary: makeShades('#6c782e'),
	colors: colors.map(c=>pTheme.palette.augmentColor({ color:{main:c}})),
	global: makeShades('#55b9b0'),
	program: makeShades('#FFD54D'),
}

function makeShades(color) {
	return {
		main: color,
		light: lighten(color, 0.2),
		lighter: lighten(color, 0.4),
		dark: darken(color, 0.2),
		darker: darken(color, 0.4)
	}
}


export default function CourseEvals(props) {
	const params = useParams();
	const [reports, setReports] = useState(null);
	const [course, setCourse] = useState(null);
	const [isPMorAdmin, setIsPMorAdmin] = useState(false);
	const [evaluationsPending, setEvaluationsPending] = useState(0);
	const [generating, setGenerating] = useState(false);
	const [regenerate, setRegenerate] = useState(false);
	const [generateError, setGenerateError] = useState(false);
	const {user} = useSelector(state=>state.data);
	const [tab, setTab] = useState(0);
	const [roleSummaryTab, setRoleSummaryTab] = useState(0);
	const theme = useTheme(); 
	const [summary, setSummary] = useState(null);
	var isMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));
	
	
	useEffect(()=>{
		jax.get(`/app/courses/${params.course_code}/reports`).then((data)=>{
			setReports(data.reports);
			setCourse(data.course);
			var admin = hasRole(user, roles.ADMIN) || (user.programs || []).map(p=>p.id).includes(data.course.program_id) ;
			setIsPMorAdmin(admin);
			setEvaluationsPending(data.course.enrollees-data.course.evaluated);
			
		});

		
	}, [props.courseCode]);

	useEffect(()=>{
		if (hasRole(user, roles.ADMIN) && reports && course && !summary) {
			setGenerating(true);
			setGenerateError(false);
			let opts = {};
			if (regenerate) {
				opts.refresh = 1;
			}
			jax.get(`/app/ai/evals/program/${course.program_id}/course/${course.id}`,opts, {dont_catch:true}).then((data)=>{
				setSummary(data);
			}).catch((e)=>{
				setGenerating(false);
				setGenerateError(true);
			}).finally(()=>{
				setRegenerate(false);
				setGenerating(false);
			})
		}
	}, [summary, reports, regenerate]);

	return <Box>
		{course && <Breadcrumbs separator="›" mb={2}>
			<RouterLink to="/">Home</RouterLink>
			{can(user,resources.PROGRAM, actions.MANAGE_COURSES) && <RouterLink to="/courses">Courses</RouterLink>}
			{course && <RouterLink to={`/courses/${course.code}`}>{course.name}</RouterLink>}
			{course && <Typography>Course Reports</Typography>}
		</Breadcrumbs>}

		{!!reports?.length && <Tabs value={tab} onChange={(e, v)=>setTab(v)} variant="scrollable" scrollButtons="auto">
			{hasRole(user, roles.ADMIN) && <Tab iconPosition="start" icon={<Lock fontSize="16px"/>} label={`Overview`} sx={{minHeight:0}}/>}
			{reports?.map((r, i)=><Tab key={i} label={`${r.name} (${r.count})`}/>)}			
		</Tabs>}
		<Divider/>
		{tab ==0 && hasRole(user, roles.ADMIN) && <Stack pt={2}>
			
			{reports && <Card variant="outlined" sx={{position:'relative'}}>
				{generating && <Stack alignItems="center" justifyContent="center" height="200px">
					<Box height="36px" width="36px" >
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="#999999" stroke="#999999" strokeWidth="5" width="30" height="30" x="25" y="85"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="#999999" stroke="#999999" strokeWidth="5" width="30" height="30" x="85" y="85"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="#999999" stroke="#999999" strokeWidth="5" width="30" height="30" x="145" y="85"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>
					</Box>
					<Box className="sub-title">{regenerate ? "Regenerating" : "Generating"} Evaluation Overview...</Box>
				</Stack>}
				{generateError && <Stack alignItems="center" justifyContent="center" height="200px" spacing={1}>
					<Box>An error occurred while generating the course summary. Please try again.</Box>
					<Button size="small" variant="text" startIcon={<Refresh/>} onClick={()=>{setRegenerate(true); setSummary(null);}}>Regenerate</Button>
				</Stack>}
				{summary && !generating && <Stack direction="column" flex={1} spacing={2} p={2}>
					<Stack direction="row" spacing={2} alignItems="flex-start" justifyContent="space-between" width="100%">
						<Stack>
							<Typography variant="h6">Course Evaluation Overview</Typography>
							
						</Stack>
						<Button size="small" variant="text" startIcon={<Refresh/>} onClick={()=>{setRegenerate(true); setSummary(null);}}>Regenerate</Button>
					</Stack>
					<Divider flexItem/>
					<Box flex={1} mt={4}>
						<Typography variant="h6">Ratings Summary</Typography>
						{summary.rating && <Rating value={summary.rating} readOnly precision={0.5}/>}
						<Typography variant="body1"><div dangerouslySetInnerHTML={{__html:summary.ratings}}></div></Typography>
					</Box>
					<Box flex={1}>
						<Stack direction="row" spacing={1} alignItems="center">
							<ChatOutlined sx={{color:theme.palette.disabled["dark"]}}/>
							<Box flex={1}><Typography color={theme.palette.disabled["dark"]} variant="h6"> Overall Feedback</Typography></Box>
						</Stack>
						<Typography variant="body1"><div dangerouslySetInnerHTML={{__html:summary.summary}}></div></Typography>
					</Box>

					{summary.role_summaries?.length && <Box flex={1}  position="relative" >
						<Stack direction="row" spacing={1} mb={1} alignItems="center">
							<Group sx={{color:theme.palette.disabled["dark"]}}/>
							<Box flex={1}><Typography color={theme.palette.disabled["dark"]} variant="h6"> Feedback by Course Role</Typography></Box>
						</Stack>
					
						<CarouselProvider isIntrinsicHeight naturalSlideWidth={400}  totalSlides={summary.role_summaries.length} visibleSlides={isMobile ? 1.1 : 3.1} infinite={false} step={1} dragEnabled={true} touchEnabled={true} >
							
							<Slider>
								{summary.role_summaries.map((r, i)=>{
									return <Slide key={i} index={i}>
										<Box px={2} mb={.25} >
											<Card elevation={3} key={i} variant="outlined" >
											
												<ExpandBox p={2} height="200px">
													<Box minHeight="200px">
														<Box mb={1} className="h5"><b>{r.role} Feedback</b></Box>
														<p className="MuiTypography-root MuiTypography-body2" style={{color: theme.palette.disabled["main"]}} dangerouslySetInnerHTML={{__html:r.summary}}></p>
													</Box>
												</ExpandBox>
												
											</Card>
										</Box>
									</Slide>})}
							</Slider>
						</CarouselProvider>
						

						
					</Box>}
					
					<Box p={1}></Box>

					<Stack direction={{xs: 'column', md: 'row'}} mt={4} spacing={4} alignItems="flex-start" justifyContent="flex-start">
						{summary.good && <Box flex={1}>
							<Stack direction="row" spacing={1} alignItems="center">
								<ThumbUpAlt sx={{color:theme.palette.success["light"]}}/>
								<Box flex={1}><Typography color={theme.palette.success["main"]} variant="h6"> Positive Feedback</Typography></Box>
							</Stack>
							<ul style={{lineHeight:"1.25rem", paddingLeft:"16px"}}>
								{summary.good.map((g,i)=><li style={{marginBottom:"8px"}} key={i}>{g}</li>)}
							</ul>
						</Box>}
						<Divider orientation="vertical" variant="middle" flexItem/>
						{summary.bad && <Box flex={1}>
							<Stack direction="row" spacing={1} alignItems="center">
								<ThumbDownAlt sx={{color:theme.palette.error["light"]}}/>
								<Box flex={1}><Typography color={theme.palette.error["main"]} variant="h6"> Negative Feedback</Typography></Box>
							</Stack>
							<ul style={{lineHeight:"1.25rem", paddingLeft:"16px"}}>
								{summary.bad.map((g,i)=><li style={{marginBottom:"8px"}} key={i}>{g}</li>)}
							</ul>
						</Box>}
						<Divider orientation="vertical" variant="middle" flexItem/>
						{summary.recommendations && <Box flex={1}>
							<Stack direction="row" spacing={1} alignItems="center">
								<TipsAndUpdatesOutlined sx={{color:theme.palette.disabled["main"]}}/>
								<Box flex={1}><Typography color={theme.palette.disabled["main"]} variant="h6"> Recommendations</Typography></Box>
							</Stack>
							<Typography variant="body1"><div dangerouslySetInnerHTML={{__html:summary.recommendations}}></div></Typography>
						</Box>}
					</Stack>
				</Stack>}
			</Card>}
		</Stack>}
		{reports?.map((r, i) => {
			return i+1 == tab && <Box pt={2} key={i}><Report data={r} key={i} isTest={false} summary={summary?.eval_summaries?.find(s=>s.eval_type_id == r.type_id)}/></Box>;
		})}
	</Box>;
}

const Report = (props) => {
	const {data, onRegenerate, isTest, noCourse, summary} = props;
	const [showProgram, setShowProgram] = useState(false);
	const [showGlobal, setShowGlobal] = useState(false);
	return <Box >
		{summary && <Box my={3}>
			<Card variant="outlined">
				<Box p={2}>
					<Stack direction="row" spacing={1} alignItems="flex-start">
						<Assistant/>
						<Stack>
							<Typography variant="h6" lineHeight={1.2}>Evaluation Summary</Typography>
							<Box mb={1} lineHeight={1.5} className="sub-title">{data.name}</Box>
							<Box mt={1}>
								<Typography variant="body1" dangerouslySetInnerHTML={{__html:summary.summary}}></Typography>
							</Box>
						</Stack>
					</Stack>
				</Box>
			</Card>
		</Box>}

		<Stack direction="row" spacing={2} alignItems="center">
		{/* {colors.map((c,i)=>{
			return <Box key={c} sx={{backgroundColor: c, width: '20px', height: '20px'}} textAlign="center" fontSize="0.7rem" pt={0.5} >{i}</Box>
		})} */}

		

		<Box flex={1}>
			<Stack direction="row" spacing={2} alignItems="center" >
				
				{!noCourse && <><Box>
					<FormControlLabel control={<Checkbox size="small" value={showProgram} sx={{color: palette.program.main, '&.Mui-checked': {color: palette.program.main}}} onChange={(e)=>setShowProgram(e.currentTarget.checked)}/>} label="Show Program Averages"></FormControlLabel>
				</Box>
				<Box width={2}/></>}
				
				<Box>
					<FormControlLabel control={<Checkbox size="small" value={showGlobal} sx={{color: palette.global.main, '&.Mui-checked': {color: palette.global.main}}} onChange={(e)=>setShowGlobal(e.currentTarget.checked)}/>} label="Show Global Averages"></FormControlLabel>
				</Box>
			</Stack>
		</Box>
		{isTest && <Box>
			<Button variant="outlined" color="primary" onClick={onRegenerate} startIcon={<Refresh/>}>Regenerate Test Data</Button>
		</Box>}
		</Stack>

		{data.sections.map((s,j)=><Box key={j} mb={6} >
			
			<Divider  >
				<Typography variant="h6">{s.title}</Typography>
			</Divider>
			<Grid container spacing={2} mt={1}>
				
					{s.questions.map((q, k)=><Grid minHeight="500px" key={k} item={true} xs={12} md={q.type == "likert" ? 6 : 6}>
						<Card variant="outlined" sx={{height:"100%", position:'relative'}} >
							<Stack sx={{height: '100%'}}>
								<Box p={2} bgcolor={palette.bg.dark} color="#ffffff">
									{q.label}
								</Box>
								
								<Box flex={1} bgcolor={palette.bg.main} position="relative">
									
									<EvalMetric data={q} count={data.count} showProgram={showProgram} showGlobal={showGlobal} noCourse={noCourse}/>
									
								</Box>
							</Stack>
						</Card>
					</Grid>)}
				
			</Grid>
		</Box>)}
	</Box>
}

const EvalMetric = (props) => {
	const {data, count, ...other} = props;
	
	if (other.noCourse) {
		other.showProgram = true;
	}

	let component = null;

	if (!data || (!data.answers?.length && !data.ratings?.length)) {
		component = <Stack height="100%" justifyContent="center" align="center" color="white" spacing={2} sx={{opacity:0.7}}>
			<Box ><ContentPasteOffOutlined fontSize="large" color="white"/></Box>
			<Box sx={{color:"#ffffff"}}>There were no responses for this question...</Box>
		</Stack>
	} else {
		switch(data.type) {
			case "likert":
				let labels = (data.likert_labels || "Poor|Needs Attention|Average|Good|Excellent");
				let ratings =  labels.split("|");
				component = <LikertChart {...other} prompt={data.prompt} data={data.ratings} ratings={ratings} entries={count} dataKey="label" type={data.type} />;
				break;
			
			case "radio":
				component = <SingleChoiceChart {...other} prompt={data.prompt} data={data.answers} count={count} dataKey="label" type={data.type}/>;
				break;
			case "multi-select":
				component = <MultipleChoiceChart {...other} prompt={data.prompt} data={data.answers} dataKey="label" count={count} type={data.type} />;
				break;
			case "multiline":
				component = <MultilineTextView  {...other} prompt={data.prompt} data={data.answers} type={data.type}/>;
				break;
			case "yes_no":
				component = <YesNoChart {...other} prompt={data.prompt} data={data.answers} dataKey="label" count={count} type={data.type} />;
				break;
			case "text":
				component = <SinglelineTextView {...other} prompt={data.prompt} data={data.answers} type={data.type}/>;
				break;
			case "number":
				component = <NumericalChart {...other} prompt={data.prompt} label={data.label} data={data.answers} type={data.type} global_mean={data.global_mean} global_std={data.global_std} program_mean={data.program_mean} program_std={data.program_std} />;
				break;
			default:
				component = <></>;
		}
	}

	return <Stack height="100%" >
		<Box p={2} pb={0} px={data.type == "likert" ? 0 : 2} textAlign="center" sx={{color:"#ffffff"}}>
			{data.prompt}
		</Box>
		<Box flex={1} position="relative">
			{component}
		</Box>
	</Stack>;
}

const CustomYAxisTick = ({ x, y, payload, anchor, verticalAnchor, color, format }) => {
    if (payload && payload.value) {
      return (
        <Text
            fontSize={"12px"}
            width={120}
			color={color || "white"}
			stroke={color || "white"}
            x={x} 
            y={y+4} 
            textAnchor={anchor || "end"}
            verticalAnchor={anchor || "center"}
        >{format ? format(payload.value) : payload.value}</Text>
      );
    }
    return null;
};

const CustomRatingAxisTick = (props) => {
	const {x, y, payload, anchor, verticalAnchor, format, ticks} = props;
    if (payload && payload.value) {
		var tick = ticks.find(x=>x.value == payload.value);
		return (
			
				<Text
					fontSize={"12px"}
					width={80}
					x={x} 
					y={y} 
					
					color="white"
					fill="white"
					textAnchor="end"
					
					verticalAnchor="start"
				>{tick.rating}</Text>
			
		  );
     
		//stars
		//return <svg x={-(payload.value-1)*6}>{Array(payload.value).fill(0).map((p,i)=><svg key={i} x={x-6+(i*12)} y={y} width={12} height={12} viewBox="0 0 18 18"><FullStar/></svg>)}</svg>;
    }
    return null;
};

const PackTopBar = (props) => {
	const {y, index, paddingTop=0, ...other} = props;
	return <Rectangle y={paddingTop+index* 40} {...other}/>
}

const NumericalChart = (props) => {
	
	const isMobile = useMediaQuery(theme=>theme.breakpoints.down('md'));
	const {data, count, showGlobal, prompt, showProgram, dataKey, label, global_mean, global_std, program_mean, program_std} = props;

	const [reduced, setReduced] = useState([]);
	const [ranks, setRanks] = useState([]);
	const [global, setGlobal] = useState([]);
	const [program, setProgram] = useState([]);
	const [maxX, setMaxX] = useState(0);

	const bellCurve = (mn, std, precision) => {
		var curve = [];
		var min = mn-4*std;
		var max = mn+4*std;

		
		const pdf = (x) => {
			const m = std * Math.sqrt(2 * Math.PI);
			const e = Math.exp(-Math.pow(x - mn, 2) / (2 * Math.pow(std,2)));
			return e / m;
		};

		for (var i = min; i<max; i+=(max-min)/precision) {
			curve.push({x: i, y: pdf(i)});
		} 
		return curve;
	}

	useEffect(()=>{
		if (data) {
			var red = [];
			var total = data.reduce((memo,cur)=>parseInt(cur.answer)+memo, 0);

			var obj = data.reduce((acc, cur)=>{
				acc[cur.answer] = (acc[cur.answer] || 0) + 1;
				return acc;
			}, {});
			red = Object.keys(obj).map(k=>{return {answer: k, resp: obj[k]};});

			let g = bellCurve(global_mean, global_std, 20);
			let p = bellCurve(program_mean, program_std, 20);

			setGlobal(g.map(i=>{return {gx: i.x, gy: i.y}}));
			setProgram(p.map(i=>{return {px: i.x, py: i.y}}));
			
			setReduced(red.sort((a,b)=>a.answer || a.x));
			setMaxX(Math.max(...red.map(x=>x.answer)));
			
		}

	}, [data, dataKey]);

	return <Box height="100%"   position="relative" color="#ffffff">
		<Box sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}} m={2}>
			<ResponsiveContainer width="100%" minHeight="100%">
				<ComposedChart data={reduced}>
					
					<XAxis dataKey="gx" hide xAxisId="global" stroke="#fff" color="#ffffff" type="number" allowDecimals={false} allowDataOverflow domain={[0, maxX]} padding={{right:isMobile ? "20" :"50"}}  />
					<YAxis dataKey="gy" hide yAxisId="global" stroke="#fff"  type="number" color="#ffffff" domain={[0, Math.max(...global.map(r=>r.gy*1.5))]} tickCount={10} tick={<CustomYAxisTick color="#ffffff"/>} />
					<Area hide={!showGlobal} type="monotone" data={global} dataKey="gy" yAxisId="global" xAxisId="global" stroke={alpha(palette.global.lighter, 0.5)} strokeWidth={1} dot={false} fill={alpha(palette.global.lighter,0.35)} />
					{showGlobal && <ReferenceLine  xAxisId="global" yAxisId="global" stroke={palette.global.lighter}  strokeDasharray="3 3" segment={[{x: global_mean, y:0}, {x: global_mean, y: Math.max(...global.map(g=>g.gy))}]} ifOverflow="visible" />}

					<XAxis dataKey="px" hide xAxisId="program" stroke="#fff" color="#ffffff" type="number" allowDecimals={false} allowDataOverflow domain={[0, maxX]} padding={{right:isMobile ? "20" :"50"}}  />
					<YAxis dataKey="py" hide yAxisId="program" stroke="#fff"  type="number" color="#ffffff" domain={[0, Math.max(...program.map(r=>r.py*1.5))]} tickCount={10} tick={<CustomYAxisTick color="#ffffff"/>} />
					<Area hide={!showProgram} type="monotone" data={program} dataKey="py" yAxisId="global" xAxisId="program" stroke={alpha(palette.program.light, 0.5)} strokeWidth={1} dot={false} fill={alpha(palette.program.light,0.35)} />
					{showProgram && <ReferenceLine  xAxisId="program" yAxisId="program" stroke={palette.program.light}  strokeDasharray="3 3" segment={[{x: program_mean, y:0}, {x: program_mean, y: Math.max(...program.map(p=>p.py))}]} ifOverflow="visible" />}
					
					
						
					<XAxis dataKey="answer" xAxisId="answer" stroke="#fff" color="#ffffff" label={{value: label, style: { textAnchor: 'middle' }, position: 'bottom', fontSize:"0.85rem", offset: -5, fill:"white"}} allowDecimals={false} allowDataOverflow domain={[0, maxX]} type="number" padding={{right:isMobile ? "20" :"50"}}  />
					<YAxis allowDecimals={false} dataKey="resp" stroke="#fff" label={{value: `# of Responses`, style: { textAnchor: 'middle' }, angle: -90, position: 'left', fontSize:"0.85rem", offset: -10, fill:"white"}} type="number" color="#ffffff" tickCount={10} tick={<CustomYAxisTick color="#ffffff"/>} />
					<Bar dataKey="resp" barSize={12} xAxisId="answer" fill={lighten(palette.primary.main,0.6)} strokeWidth={1} stroke={false} style={{filter:"drop-shadow( 1px 0px 0px rgba(0, 0, 0, 0.2))"}} ></Bar>
					
					
				</ComposedChart>
			</ResponsiveContainer>
		</Box>
	</Box>;
}

const MultipleChoiceChart = (props) => {
	
	const isMobile = useMediaQuery(theme=>theme.breakpoints.down('md'));
	const {data, count, showGlobal, prompt, showProgram, dataKey, labelKey} = props;

	const [reduced, setReduced] = useState([]);
	const [ranks, setRanks] = useState([]);

	useEffect(()=>{
		if (data && dataKey) {
			var reduced = data.reduce((acc, cur)=>{
				cur.answer.split('|').filter(x=>!!x).map(a=>{
					let item = acc.find(x=>x.name==a);
					if (!item) {
						acc.push({name: a, value: 1});
					} else {
						item.value++;
					}

				});
				
				return acc;
			}, []).sort((a,b)=>b.value-a.value).map(x=>{return {...x, percent: x.value/count*100}});

			var ranked = reduced.map(function(v){ return reduced.indexOf(v)+1 });
			setRanks(ranked);

			setReduced([...reduced, ...Array(reduced.length < 10 ? 10-reduced.length : 0).fill({name: "", value: null})]);
		}

	}, [data, dataKey]);
	
	
	return <Box height="100%"   position="relative" color="#ffffff">
		<Box sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}} m={2}>
			<ResponsiveContainer width="100%" minHeight="100%">
				<ComposedChart data={reduced} layout="vertical" >
					<Tooltip  allowEscapeViewBox={false} cursor={false} content={<MultiChoiceTooltip count={count}/>}/>
					
					<XAxis hide type="number" ticks={false} padding={{right:isMobile ? "20" :"50"}}  />
					
					{/* <Tooltip  offset={20} content={<LikertTooltip/>} wrapperStyle={{zIndex: 100000, right:0}} cursor={false} filterNull /> */}
					<Bar top={0} tooltipType="none" bottom="auto" dataKey="value" barSize={22}  spacing={0} activeBar={{fill:palette.primary.lighter}} >
						{reduced?.map((entry, index) => {
							return <Cell key={`cell-${index}`} fill={alpha(palette.primary.lighter, 1-Math.min(ranks[index], 6)*0.1375)} />
						})}
						<LabelList dataKey="name" fill="white" fontSize="0.8rem" fontWeight="bold" textTransform="uppercase" position="insideLeft"  offset={10} style={{marginLeft: "4px", textTransform:"uppercase"}}/> 
						<LabelList dataKey="percent" fill="white" fontSize="0.8rem" position="right" formatter={(v)=>`${Math.floor(v*10)/10}%`} /> 
					</Bar>
					
					<YAxis type="category" dataKey="name" tick={null} width={20} axisLine={{strokeWidth:'2px',  stroke: "white", }}  />
					
				</ComposedChart>
			</ResponsiveContainer>
		</Box>
	</Box>;
  }

  const MultilineTextView = (props) => {
	const {data } = props;

	const [index, setIndex] = useState(0);

	const theme = useTheme();

	function getHtmlText(text) {
		return `&quot;${(text || "").replace(/\n/g, "<br/><br/>")}&quot;`;
	}

	return data && <Box p={4} pt={2} position="absolute" top="0" left="0" right="0" bottom="0">
		<Card elevation={4} sx={{height:"100%", maxHeight:"100%"}} >
			<Stack height="100%">
				<Box sx={{flex:1, overflowY:'auto'}}>
					<Box p={2} fontStyle="italic" color={theme.palette.disabled.main}  dangerouslySetInnerHTML={{__html:getHtmlText(data && data[index]?.answer)}}>
						
					</Box>
				</Box>
				<Divider/>
				<Box>
					<MobileStepper sx={{flex:1}} variant="text" position="static" steps={data.length} activeStep={index} nextButton={<Button size="small" endIcon={<ArrowForwardIos/>} disabled={index >= data.length-1} onClick={()=>setIndex(index+1)}>Next</Button>} backButton={<Button size="small" startIcon={<ArrowBackIos/>} disabled={index == 0} onClick={()=>setIndex(index-1)}>Prev</Button>}>
						
					</MobileStepper>
				</Box>
			</Stack>
		</Card>
	</Box>
  }

  const SinglelineTextView = (props) => {
	const {data } = props;

	const [index, setIndex] = useState(0);

	

	function getHtmlText(text) {
		return `&quot;${(text || "").replace(/\n/g, "<br/><br/>")}&quot;`;
	}

	return data && <><Box p={4} pt={2} position="absolute" top={16} left="0" right="0" bottom="0"  sx={{overflowY: 'auto', scrollbarColor: `#ffffff66 ${palette.bg.main}`}}>
		<Stack spacing={2} mb={4}>
			{data.map((d,i)=><Card key={i} elevation={4} >
				<Box p={2} dangerouslySetInnerHTML={{__html:getHtmlText(d.answer)}}>
				</Box>
			</Card>)}
		</Stack>
		
	</Box>
	<Box position="absolute" left="0" right="0" bottom="0" height="20%"  bgcolor="white" sx={{background: `linear-gradient(0deg, ${palette.bg.main}, transparent)`}}>

	</Box></>;
  }

  const SingleChoiceChart = (props) => {
	
	const isMobile = useMediaQuery(theme=>theme.breakpoints.down('md'));
	const {data, dataKey, count, type} = props;

	const [reduced, setReduced] = useState([]);
	const [highlighted, setHighlighted] = useState(null);

	useEffect(()=>{
		if (data && dataKey) {
			var reduced = data.reduce((acc, cur)=>{
				let item = acc.find(a=>a.name==cur.answer);
				if (!item) {
					acc.push({name: cur.answer, value: 1});
				} else {
					item.value++;
				}
				return acc;
			}, []);

			setReduced(reduced.sort((a,b)=>b.value-a.value));
		}

	}, [data, dataKey]);

	const renderSlice = (props,a,b) => {
		const RADIAN = Math.PI / 180;
		const {cx, cy, fill, innerRadius, outerRadius, startAngle, endAngle, midAngle, name, type} = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);

		const isActive = highlighted == props.name;
		const sx = cx + (isActive ? 10 : 0) * cos;
		const sy = cy + (isActive ? 10 : 0) * sin;

		var sfill = alpha(fill, 0.6);

		if (isActive) {
			sfill = fill;
		}

		return (
		  <Sector cx={cx} cy={cy} innerRadius={innerRadius != 0 && isActive ? innerRadius+10 : innerRadius} outerRadius={outerRadius+(isActive ? 10 : 0)} startAngle={startAngle} endAngle={endAngle} fill={sfill} strokeWidth={1} stroke={isActive ? "white" : false}/>
		);
	  };

	//const sColors = [];
	var sColors = Array(6).fill().map((a,i)=>lighten(palette.primary.darker,i*0.15) );
	return <Box height="100%" position="relative">
		<Box sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}} m={2}>
			<ResponsiveContainer width="100%" minHeight="100%">
				<PieChart >	
					<Legend verticalAlign="middle" align="right" layout="vertical" height={36} spacing={8} iconType="circle" sx={{'& li':{marginTop:'4px'}}} wrapperStyle={{height:'auto', padding: '8px', background:"#ffffff22", border: 'solid 1px #ffffff99', borderRadius: '8px'}} />
					<Pie activeIndex={highlighted} data={reduced} startAngle={90} endAngle={450} innerRadius={0} dataKey="value" nameKey="name" fill="#8884d8"  inactiveShape={renderSlice} activeShape={renderSlice} onMouseOver={(data,i)=>{setHighlighted(data.name)}} onMouseLeave={(data,i)=>highlighted == data.name && setHighlighted(null)} >
						{reduced?.map((entry, index) => {
							let color = sColors[index % sColors.length];
			
							//return <Sector key={`sector-${index}`} cx="10%" cy="10%"  innerRadius={0} outerRadius={80} fill={pColors[index % pColors.length]} stroke={false} />;
							return <Cell  key={`cell-${index}`}  fill={color} type={type} stroke={false} />
  						})}
					</Pie>
					<Tooltip offset={20} count={count} content={<SingleChoiceTooltip count={count} />} allowEscapeViewBox={false} cursor={{fill:'#00000011'}}/>
				</PieChart>
			</ResponsiveContainer>
		</Box>
	</Box>;
  }

  const YesNoChart = (props) => {
	
	const isMobile = useMediaQuery(theme=>theme.breakpoints.down('md'));
	const {data, dataKey, count, type, showGlobal, showProgram} = props;

	const [reduced, setReduced] = useState([]);
	const [highlighted, setHighlighted] = useState(null);

	useEffect(()=>{
		if (data && dataKey) {
			var reduced = data.reduce((acc, cur)=>{
				let item = acc.find(a=>a.name==cur.answer);
				if (!item) {
					acc.push({name: cur.answer, value: 1});
				} else {
					item.value++;
				}
				return acc;
			}, []);

			setReduced(reduced);
		}

	}, [data, dataKey]);

	const renderSlice = (props,a,b) => {
		const RADIAN = Math.PI / 180;
		const {cx, cy, fill, innerRadius, outerRadius, startAngle, endAngle, midAngle, stroke, strokeWidth, name, type} = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);

		const isActive = highlighted == props.name;
		const sx = cx + (isActive ? 10 : 0) * cos;
		const sy = cy + (isActive ? 10 : 0) * sin;

		var sfill = fill;

		// if (isActive) {
		// 	sfill = alpha(fill, 1);
		// } else {
		// 	sfill = alpha(fill, 0.6);
		// }
		let sFill = fill;
		return <g>
			
		  <Sector cx={cx} cy={cy} innerRadius={isActive ? innerRadius+10 : innerRadius} outerRadius={outerRadius+(isActive ? 10 : 0)} startAngle={startAngle} endAngle={endAngle} fill={sfill} strokeWidth={strokeWidth} isFront={isActive} />
		  
		</g>;
	  };
	
	  const renderLabel = (props) => {
		const RADIAN = Math.PI / 180;
		const {cx, cy, midAngle, innerRadius, outerRadius, percent, name, index} = props;
		const radius = outerRadius + 25;// + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
		return <>
			<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			  {name}
			</text>
			<text x={x} y={y} dy={18} opacity={0.75} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			  {`${(percent * 100).toFixed(0)}%`}
			</text>
		</>;
	  }

	
	return <Box height="100%" position="relative" sx={{textTransform: "capitalize"}}>
		<Box sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}} m={2}>
			<ResponsiveContainer width="100%" minHeight="100%">
				<PieChart >	
				<defs>
				<radialGradient id="grad" fx="0%" fy="0%" r="100%" spreadMethod="pad">
					<stop offset="0%" stopColor={alpha(palette.primary.lighter, 0.2)} />
					<stop offset="100%" stopColor={alpha(palette.primary.lighter, 1)} />
				</radialGradient>
				</defs>
					<Pie activeIndex={highlighted} label={renderLabel}  data={reduced} startAngle={90} endAngle={450} innerRadius="50%" dataKey="value" nameKey="name" fill="#8884d8"  inactiveShape={renderSlice} activeShape={renderSlice} onMouseOver={(data,i)=>{setHighlighted(data.name)}} onMouseLeave={(data,i)=>highlighted == data.name && setHighlighted(null)} >
						{reduced?.map((entry, index) => {

							let color = entry.name?.toLowerCase() == "yes" ? palette.primary.lighter : alpha(palette.primary.lighter, 0.25); 
							
							return <Cell  key={`cell-${index}`} innerRadius="50%" fill={color} type={type} stroke={palette.bg.main} strokeWidth={3} />
  						})}
					</Pie>
					{showProgram && <Pie data={reduced} startAngle={90} endAngle={205} innerRadius="46%" outerRadius="47.5%" stroke={false} dataKey="value" nameKey="name" fill={palette.program.main} >
					</Pie>}
					{showGlobal && <Pie data={reduced} startAngle={90} endAngle={145} innerRadius="42%" outerRadius="43.5%" stroke={false} dataKey="value" nameKey="name" fill={palette.global.main} >
					</Pie>}
					<Tooltip offset={20} count={count} content={<SingleChoiceTooltip count={count} />} allowEscapeViewBox={false} cursor={{fill:'#00000011'}}/>
				</PieChart>
			</ResponsiveContainer>
		</Box>
	</Box>;
  }

const BaseTooltip = (props) => {
	const {payload, title, subtitle, active, children, colorBar} = props;
	if (active && payload && payload.length) {
		var color = payload[0].payload?.fill;
		var question = payload[0].payload.prompt;
		return <Paper elevation={3} sx={{p:1}}>
			<Stack direction="row" alignItems="stretch" spacing={1}>
				{!!colorBar && <Box width={4} bgcolor={color} borderRadius={2}>

				</Box>}
				<Box>
					{title && <Box>
						<Typography sx={{fontSize: '1rem', fontWeight:"bold"}}>{title}</Typography>
					</Box>}
					{subtitle && <i className='sub-title'>{subtitle}</i>}
					<Box>
						{children}
					</Box>
				</Box>
			</Stack>
		</Paper>;
	}
	return null;
}

const SingleChoiceTooltip = (props) => {
	const value = props.payload[0]?.value;
	const perc = value/props.count*100;
	return <BaseTooltip title={props.payload[0]?.name} colorBar={true} {...props}>
		{value} Respondents <span className="sub-title">({perc.toFixed(1)}%)</span>
	</BaseTooltip>;
}

const MultiChoiceTooltip = (props) => {
	const value = props.payload[0]?.value;
	const perc = value/props.count*100;
	return <BaseTooltip title={props.payload[0]?.payload?.name} colorBar={true} {...props}>
		{value} Respondents <span className="sub-title">({perc.toFixed(1)}%)</span>
	</BaseTooltip>;
}

const LikertTooltip = (props) => {
	const {payload, label, active, ratings, lcolors, programCol, globalCol, showProgram, showGlobal, noCourse} = props;
	if (active && payload && payload.length) {
		var question = payload[0].payload.prompt;
		return <Box maxWidth={350}>
			<Paper elevation={3} sx={{p:1}}>
				<Box>
					<Typography sx={{fontSize: '1rem', fontWeight:"bold"}}>{label}</Typography>
				</Box>
				<Box>
					<i className='sub-title'>{question}</i>
				</Box>
				<Stack sx={{fontSize:'0.8rem', textTransform:'capitalize'}} mt={2} spacing={0.5}>
					<Stack  direction="row" alignItems="flex-start" justifyContent="space-between" spacing={3}>
						{!noCourse && <Stack  spacing={1}>
						{ratings.map((r,i)=>{
							if (payload[0].payload[`s${i+1}`]) {
								return <Stack key={i} direction="row" alignItems="center" spacing={1}>
									<Box height={16} width={16} bgcolor={lcolors[i]}>

									</Box>
									<Box>
										<b>{r}:</b> {payload[0].payload.ratings[i+1]}
									</Box>
								</Stack>;
							}
						})}
						</Stack>}
						<Stack  spacing={1}>
							
							{!noCourse && <Stack  direction="row" alignItems="center" spacing={1}>
								<Box height={16} width={16} bgcolor={palette.primary.main}>
								</Box>
								<Box>
									Course: {payload[0].payload.course.toFixed(2)}
								</Box>
							</Stack>}
							{showProgram && <Stack  direction="row" alignItems="center" spacing={1}>
								<Box height={16} width={16} bgcolor={palette.program.main}>
								</Box>
								<Box>
									Program: {payload[0].payload.program.toFixed(2)}
								</Box>
							</Stack>}
							{showGlobal && <Stack  direction="row" alignItems="center" spacing={1}>
								<Box height={16} width={16} bgcolor={palette.global.main}>
								</Box>
								<Box>
									Global: {payload[0].payload.global.toFixed(2)}
								</Box>
							</Stack>}
						</Stack>
					</Stack>
					
				</Stack>
				
			</Paper>
		</Box>;
	}

	return null;
};
{/* <svg fill="#000000" width="64px" height="64px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, -1, 0, 0)"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M21,21H3L12,3Z"></path></g></svg> */}
const Caret = (props) => {
	const {width, height, fill, x, y, cx, cy, fontSize, direction, offsetY=0, offsetX=0} = props;
	return <g  transform={`translate(${cx-5},${cy+offsetY})`}><g transform="matrix(1, 0, 0, -1, 0, 0)"><path d="M10,10H0L5,3Z" fill={fill}></path></g></g>;
	return <text width={16} height={16} x={cx+offsetX} y={cy+offsetY-20} isFront={true} text-align="center"  font-size={fontSize} fill={fill}>&#8227;</text>;
}

const LikertChart = (props) => {
	
	
	const isMobile = useMediaQuery(theme=>theme.breakpoints.down('md'));
	
	const {data, dataKey, entries, ratings, labelKey, showProgram, showGlobal, noCourse} = props;

	const [reduced, setReduced] = useState([]);
	const [ticks, setTicks] = useState([]);
	
	var real_entries = data?.length && Object.keys(data[0].ratings).reduce((acc,cur)=>acc+data[0].ratings[cur],0);
	var max = ratings.length*real_entries;

	useEffect(()=>{
		var tks = ratings.map((r,i)=>{
			return {
				value: (i+1)*(max/ratings.length),
				rating: r
			}
		}).reverse();
		
		setTicks(tks);
	}, ratings);

	useEffect(()=>{
		if (data && dataKey) {
			var red = data.map((d, i)=>{ 
				//var max = ratings.length*real_entries;
				let scale = 1;
				
				var total = 0;
				ratings.map((r,j)=>{
					if (d.ratings && d.ratings[j+1]) {
						d[`s${j+1}`] = d.ratings[j+1]*(j+1);
						total += d[`s${j+1}`];
					}
				});

				let avg = (d.course/ratings.length)*real_entries;
				d.padding=(real_entries-avg)/2;
				d.avg_point = (d.course/ratings.length)*(real_entries);

				//scale the global and program values to the same scale as the course (needs to fit the xAxis)

				d.gScaled = (d.global)/ratings.length*real_entries;
				d.pScaled = (d.program)/ratings.length*real_entries;
				
				return d;
			});
			setReduced(red);
		}
	}, [data, dataKey]);

	//var lcolors = [colors[10], colors[8], colors[6], colors[4], colors[5]];
	//var lcolors = ['#6d7a2e','#7f8a47','#919a60','#a3aa79','#b5ba93','#c8cbad'].reverse();
	//var lcolors = ['#44a248','#44a248dd','#44a248bb','#44a24899','#44a24877','#44a24855'].reverse();
	var lightest = lighten(palette.primary.lighter, 0.5);
	//var lcolors = [lightest, alpha(lightest, 0.8), alpha(lightest, 0.6), alpha(lightest, 0.4), alpha(lightest, 0.2)].reverse();
	var darkest = darken(palette.primary.darker, 0);
	//var lcolors = [darkest, alpha(darkest, 0.8), alpha(darkest, 0.6), alpha(darkest, 0.4), alpha(darkest, 0.2)].reverse();
	var lcolors = [palette.primary.darker, palette.primary.dark, palette.primary.main, palette.primary.light, palette.primary.lighter];
	return <Box height="100%" p={0} position="relative" >
		<Box sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'visible'}} my={2} mr={1}>
			<ResponsiveContainer width="100%" minHeight="100%">
				<BarChart data={reduced} barSize={12} barGap={3} barCategoryGap={6} layout="vertical" overflow="hidden" >
					<CartesianGrid vertical={true} horizontal={false} stroke="#ffffff22" />
					<XAxis id="xAxis" type="number" color="#ffffff" tick={<CustomRatingAxisTick ticks={ticks}/>} tickCount={ratings.length} domain={[0,max]} ticks={ticks?.map(t=>t.value)} interval={0}   />
					<YAxis id="yAxis" type="category" tickLine={false} tick={{fill:'white', overflow: "hidden", scaleToFit: true, fontSize:'0.8rem'}} width={160} dataKey={dataKey} axisLine={false} />
					{/* <Customized component={<LikertBar/>} /> */}
					<Tooltip offset={20}  content={<LikertTooltip ratings={ratings} noCourse={noCourse} lcolors={lcolors} programCol={palette.program.main} globalCol={palette.global.main}/>} allowEscapeViewBox={{x:false,y:false}} cursor={{fill:'#00000011'}} showProgram={showProgram} showGlobal={showGlobal}/>
					
					
					{/* <Bar dataKey="padding" fill="none" spacing={0} stackId="p" >
					</Bar>	 */}

					
					{!noCourse && ratings?.map((c,i)=>{
						
						return <Bar dataKey={`s${i+1}`} fill={lcolors[i % lcolors.length]} spacing={1} key={i} stackId="p" style={{ stroke: palette.bg.main, strokeWidth: 1, '& text': {textAnchor:'end'} }}>
							{i == ratings.length-1 && <LabelList dataKey={`course`} fill="white" fontSize="0.75rem" position="right" style={{transform:'translate(-5px, -12px)', textAnchor:'end'}} formatter={(v,e)=>`${(Math.round(v*10)/10).toFixed(1)}`} />}
						</Bar>	
					})}
					<Bar hide={!showProgram} dataKey={`pScaled`} fill={palette.program.main} spacing={1} barSize={noCourse ? 8 : 3} style={{ stroke: palette.bg.main, strokeWidth: 1 }}/>	
					<Bar hide={!showGlobal} dataKey={`gScaled`} fill={palette.global.main} spacing={1} barSize={3} style={{ stroke: palette.bg.main, strokeWidth: 1 }}/>
					
					{/* {data.map((d,i)=>{
						return <ReferenceDot xAxis="xAxis" yAxis="yAxis" x={d.avg_point} y={d.label} isFront={true} fill="#ffffff" shape={<Caret fill="#ffffff" offsetY={-3} fontSize="28px"/>} />
					})} */}				
					
				</BarChart>
			</ResponsiveContainer>
			
		</Box>
		{/* <Box>Question: {data[0]?.question_id}</Box>
		<Box>Tick 1: {ticks[0]?.value}</Box>
		<Box>Domain: {reduced[0]?.entries}</Box> */}
	</Box>;
}

const ExpandStack = styled(Stack)((props) => ({
	
	position: 'relative',
	'& .expand-box-content': {
		transition: 'height 0.3s',
		maxHeight: props.ht,
		overflow: 'hidden',
		'&.expanded': {
			maxHeight: 'none',
			paddingBottom: '1rem',
			'& .expander': {
				background: 'none'
			}
		}
	},
	'& .expander': {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%)',	
	}
}));

const ExpandBox = (props) => {
	const {height, children,  ...other} = props;
	const [expanded, setExpanded] = useState(false);
	const [overflow, setOverflow] = useState(false);
	
	var contentRef = useRef(null);
	useEffect(()=>{
		if (contentRef.current) {
			setOverflow(contentRef.current.scrollHeight > contentRef.current.clientHeight);
		}
	}, []);

	return <ExpandStack ht={height} {...other} spacing={0}>
		
		<Box className={`expand-box-content ${expanded ? 'expanded' :''}`} ref={contentRef} >
			{children}
			{overflow && <Box className="expander" pt={6} textAlign="center">
				<Button className="expand-box-button" onClick={()=>setExpanded(!expanded)}>{expanded ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}</Button>
			</Box>}
		</Box>
		
		

	</ExpandStack>
}

export {Report};