import React, { useEffect, useState } from 'react';
import jax from '../../../helper/jax';
import { useParams } from 'react-router-dom';
import Section, { NewItemBox } from '../Section';
import { Alert, AlertTitle, alpha, Box, Breadcrumbs, Button, Card, Dialog, Divider, Skeleton, Slide, Stack, Step, StepButton, StepContent, StepIcon, StepLabel, Stepper, styled, TextField, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { PaperClasses } from '@mui/material';
import { Add, AddCircleOutline, ArrowBack, ArrowBackIos, Assessment, CopyAll, LocalFireDepartment, Publish, Replay, Save, SaveAs, Science, Unpublished } from '@mui/icons-material';
import Eval from '../Eval';
import { copyToClipboard, short_id } from '../../../helper/util';
import Notification from '../../Notification';
import { Report } from '../../../training/reports/CourseEvals';
import {LoremIpsum} from 'lorem-ipsum';
import { mean, standardDeviation } from 'simple-statistics';

const HoverCard = styled(Card)(({ theme }) => ({
	opacity: 0,
	maxHeight: 200,
	transition: 'opacity 1s, max-height 0s 1s',
	'&.editing': {
		//overflow: 'visible'
	},
	'&:hover': {
		boxShadow: theme.shadows[6]
	},
	'&.highlight': {
		zIndex: 20,
		boxShadow: theme.shadows[6]
	},
	'&.active': {
		maxHeight: '100%',
		display: 'block',
		opacity: 1,
		transition: 'opacity 1s, max-height 0s 1s'
	}
}));

const SectionStepper = styled(Stepper)(({ theme }) => ({
	'& .MuiStep-root:not(:last-child)': {
		'& .MuiStepLabel-root > *': {
			opacity: 0.75,
			'&.Mui-active': {
				opacity: 1,
				'& + *': {
					opacity: 1
				}
			}
		},
		'& .MuiStepLabel-root:hover > *': {
			opacity: 1,
			'&.MuiStepLabel-iconContainer:not(.Mui-active)': {
				border: `solid 4px ${theme.palette['disabled'].light}`,
				borderRadius: '100px',
				marginTop:'-5px',
				marginBottom: '-5px',
				'& svg': {
					border: 'solid 1px white',
					borderRadius: '100px'
				}
			}
		},
		'& .MuiStepLabel-iconContainer.Mui-active': {
			border: `solid 4px ${theme.palette['primary'].main}`,
			borderRadius: '100px',
			animation: `throb 1s infinite alternate ease-in-out`,
			marginTop:'-5px',
			marginBottom: '-5px',
			'& svg': {
				border: 'solid 1px white',
				borderRadius: '100px'
			}
		},
		"@keyframes throb": {
			"0%": {
				borderColor: alpha(theme.palette['primary'].main, 0.1),
			},
			"100%": {
				borderColor: alpha(theme.palette['primary'].main, 1),
			}
		}
	}

}));

const BackdropBox = styled(Box)(({ theme }) => ({
	position: "fixed",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	background: "#00000000",
	zIndex: -1,
	transition: "background 1s, z-index 1s",
	'&.show': {
		zIndex: 10,
		background: "#00000035",
		transition: "background 0.5s"
	}
}));

interface Props {
	
}

const EvalEditor: React.FC<Props> = () => {
	
	const [schema, setSchema] = useState<any>(null);
	const [savedSchema, setSavedSchema] = useState<any>(null);
	const [ evaluation, setEvaluation] = useState<any>(null);
	const { type, version, uuid } = useParams(); 
	const [hasChanges, setHasChanges] = useState<boolean>(false);
	const [editSection, setEditSection] = useState<any>(null);
	const [showSection, setShowSection] = useState<any>(0);
	const [showTest, setShowTest] = useState<boolean>(false);
	const [showReport, setShowReport] = useState<any>(null);
	const [sampleData, setSampleData] = useState<any>(null);
	const [confirm, setConfirm] = useState<any>(null);
	const [regenerate, setRegenerate] = useState<boolean>(false);
	const [editEl, setEditEl] = useState<any>(null);
	const [newSectionName, setNewSectionName] = useState<string>("");

	const theme = useTheme();

	const loadSchema = () => {
		if (uuid) {
			jax.get(`/app/evaluations/__${uuid}`).then((res) => {
				setSchema(res.schema);
				setEvaluation(res.eval);
				setHasChanges(false);
			});
		} else if (version) {
			jax.get(`/app/evaluations/${type}/${version}`).then((res) => {
				setSchema(res.schema);
				setEvaluation(res.eval);
				setHasChanges(false);
			});
		} else {
			jax.get(`/app/evaluations/${type}`).then((res) => {
				setSchema(res.schema);
				setEvaluation(res.eval);
				setHasChanges(false);
			});
		}
	}

	useEffect(() => {
		if (schema && !savedSchema) {
			setSavedSchema(cloneDeep(schema));
			setHasChanges(false);
		} else if (schema && savedSchema) {
			setHasChanges(!isEqual(schema, savedSchema));
		}
	}, [schema, savedSchema]);

	useEffect(()=>{
		if (schema) {
			var data = createSampleData(schema, 25);
			setSampleData(data);
		}
	}, [schema, regenerate]);

	useEffect(() => {
		loadSchema();
	}, []);

	const moveSection = (arr, from, to) => {
		if (to !== 0 && !to) {
			return arr;
		}
		var el = arr.splice(from, 1)[0];
		arr.splice(to, 0, el);
		return arr.map((o,i)=>{
			if (o.sequence !== undefined) {
				o.sequence = i;
			}
			return o;
		});
	}

	const updateSection = (section: any) => {
		const newSchema = {...schema};
		let oldSeq = null;
		newSchema.sections = schema.sections.map((s: any, i: number) => {
			if(i == editSection) {
				oldSeq = s.sequence;
				return {...s, ...section};
			}
			return s;
		});

		if (section.new_sequence !== undefined && oldSeq != section.new_sequence) {
			moveSection(newSchema.sections, oldSeq, section.new_sequence);
			setShowSection(section.new_sequence);
		}

		setHasChanges(!isEqual(newSchema, schema));

		setSchema(newSchema);
		
		setEditSection(null);
	};

	const createSection = (e) => {
		e.preventDefault();
		const newSchema = {...schema};
		newSchema.sections.push({eval_type_id: schema.id, sequence: schema.sections.length, title: newSectionName, blocks: []});
		setSchema(newSchema);
		setEditSection(schema.sections.length-1);
		setShowSection(schema.sections.length-1);
		setEditEl(null);
	}

	const deleteSection = (id) => {
		const newSchema = {...schema};
		newSchema.sections = schema.sections.filter((s: any, i: number) => {
			return s.id != id;
		});
		setSchema(newSchema);
	}

	const publish = (e) => {
		if (e !== true && e?.currentTarget && !confirm?.okay) {
			setConfirm({el: e.currentTarget, text:`Publish version ${schema.version}?`, fn: publish});
			return;
		}
		jax.post(`/app/evaluations/publish`, {id: schema.uuid}).then((res) => {
			setSchema({...schema, published: true});
		});
	}

	const unpublish = (e) => {
		if (e !== true && e?.currentTarget && !confirm?.okay) {
			setConfirm({el: e.currentTarget, text:"Are you sure you want to unpublish this version?", fn: unpublish});
			return;
		}
		jax.post(`/app/evaluations/unpublish`, {id: schema.uuid}).then((res) => {
			setSchema({...schema, published: false});
		});
	}

	const revert = (e) => {
		if (e !== true && e?.currentTarget && !confirm?.okay) {
			setConfirm({el: e.currentTarget, text:"Are you sure you want to revert all changes since the last save?", fn: revert});
			return;
		}
		setSchema(savedSchema);
	}
	

	const save = (create_new) => {

		if (create_new) schema.id = null;
		//sequence everything
		schema.sections.map((s: any, i: number) => {
			s.sequence = i;
			if (create_new) s.id = null;
			s.blocks.map((b: any, j: number) => {
				b.sequence = j;
				if (create_new) b.id = null;
				b.questions.map((q: any, k: number) => {
					q.sequence = k;
					if (create_new) q.id = null;
					q.choices?.map((c: any, l: number) => {
						c.sequence = l;
						if (!c.code) {
							c.code = short_id(true);
						}
						if (create_new) c.id = null;
					});
				});
			});
		});

		jax.post(`/app/evaluations/schema`, schema).then((res) => {
			setSavedSchema(cloneDeep(res));
			setSchema(res);
		}).catch((err) => {
			debugger;
		});
	}

	const copyLink = () => {
		copyToClipboard(schema.link); 
	}

	return <>
		<Eval schema={schema} show={!!showTest} maxBodyHeight={600} onClose={()=>setShowTest(false)}/>

		<Dialog open={!!showReport} onClose={()=>setShowReport(null)} fullWidth maxWidth="lg">
			<Box p={2}>
				<Report data={sampleData} onRegenerate={()=>setRegenerate(!regenerate)} isTest={true}/>
			</Box>
		</Dialog>

		<Notification anchorEl={confirm?.el} open={!!confirm} {...confirm?.options} onClose={()=>setConfirm(null)}>
			<Box fontSize="0.9rem">{confirm?.text}</Box>
			<Stack direction="row" mt={2} spacing={1}>
				<Button size="small" onClick={()=>setConfirm(null)}>Cancel</Button>
				<Box sx={{flex:1}}></Box>
				<Button size="small" variant="outlined" onClick={()=>{confirm?.fn(true); setConfirm(null);}}>Yes, continue</Button>
			</Stack>
		</Notification>

		<Stack  p={2} >			
			<Stack direction="row" my={2} mb={3} justifyContent="space-between" alignItems="flex-start">
				
				<Box flex={1}>
					<Breadcrumbs separator={<Box className="sub-title">/</Box>}> 
						<RouterLink className="sub-title" color="text.secondary" to={`/admin/evals`}>
							<Stack direction="row" alignItems="center" spacing={0.5}>
								<ArrowBackIos fontSize='inherit'/> 
								<Box>All Evaluations</Box>
							</Stack>
						</RouterLink> 
					</Breadcrumbs>
					<Box mt={1}>
						<h2 >{evaluation && schema ? `${evaluation.name} (Ver. ${schema.version})`  : <Skeleton></Skeleton>}</h2> 
					</Box>
				</Box>
				
			</Stack>

			{schema && <Stack direction="row" justifyContent="flex-end" alignItems="stretch" mb={4} spacing={2}>
				<Alert  sx={{flex:4}} severity={hasChanges ? "warning" : "success"}>
					<Stack justifyContent="space-between" alignItems="center" flex={1} sx={{height:"100%"}}>
						<Box alignSelf="flex-start">{!hasChanges ? <AlertTitle>Version {schema?.version} Saved</AlertTitle> : <AlertTitle>Unsaved Changes</AlertTitle>}</Box>
						<Box className="sub-title" flex={1}>
							{schema.submissions > 0  && <Box flex={1} textAlign="left">This version of the evaluation has <b>{schema.submissions} prior submissions</b>.  It cannot be modified, but a new version can be created with changes. </Box>}
							{schema.submissions == 0  && <Box flex={1} textAlign="left">There are no prior submissions for this version of the evaluation.  You can save changes to this version or choose to save them to a new version. </Box>}
						</Box>
						<Stack direction="row" spacing={2} justifyContent="center" mt={1} mb={1}>
							{schema.submissions == 0  && <Button size="small" disabled={!hasChanges || editSection !== null} variant="contained" color="warning" onClick={()=>save(false)} startIcon={<Save/>}> Save Changes</Button>}
							<Button size="small" variant={schema.submissions > 0 ? "contained" : "outlined"} color={hasChanges ? "warning" : "success"} disabled={!hasChanges || editSection !== null} onClick={()=>save(true)} startIcon={<SaveAs/>}> Save As New Version</Button>
							
						</Stack>
						{hasChanges && <Box>
							<Button  size="small" variant="text" color="disabled" disabled={editSection !== null} onClick={revert} startIcon={<Replay/>}> Revert All Changes</Button>
						</Box>}
						{/* {editSection !== null && <Box className="sub-title xs" pt={2}>A section is currently being edited...</Box>} */}
						{/* {editSection === null && !hasChanges && <Box className="sub-title xs" pt={2}>No changes have been made...</Box>}
						{editSection === null && hasChanges && <Box className="sub-title xs" color={theme.palette.warning.main} pt={2}>Changes have been applied, but not saved.  </Box>} */}
						
					</Stack>
				</Alert>
				
				<Alert sx={{flex:2}} severity={schema.published ? "success" : "info"}>
					<Stack justifyContent="space-between" flex={1} sx={{height:"100%"}}>
						{schema.published ? <AlertTitle>Published</AlertTitle> : <AlertTitle>Draft Status</AlertTitle>}
						<Box flex={1}>
							{!schema.published && <Box>This version is not available to distribute or use in Course Types until it has been published.</Box>}
							{schema.published && <Box>Provide a link to gather submissions or associate it with a Course Type to gather feedback at the end of a course.</Box>}
						</Box>
						<Stack mt={2} direction="column" justifyContent="center" alignItems="center" spacing={1}>
							{!schema.published && <Button size="small" disabled={editSection !== null || hasChanges} variant="contained" color="info" onClick={publish} startIcon={<Publish/>}>Publish Version {schema.version}</Button>}
							{!!schema.published && <Button size="small" variant="outlined" color="primary" onClick={unpublish} startIcon={<Unpublished/>}>Unpublish</Button>}
							{!!schema.published && <Button size="small" variant="outlined" color="primary" onClick={copyLink} startIcon={<CopyAll/>}>Copy Link</Button>}
						</Stack>
						
					</Stack>
				</Alert>

				<Alert sx={{flex:2}} severity="info" icon={<Science/>}>
					<Stack justifyContent="space-between" flex={1} sx={{height:"100%"}}>
						<AlertTitle>Test</AlertTitle>
						
						<Box flex={1}>See how users will experience this evaluation.</Box>
						<Stack direction="column" spacing={1} mt={2} textAlign="center" justifyContent="flex-start">
							<Button size="small" variant="contained" color="info"  disabled={editSection !== null} onClick={()=>{setShowTest(true)}} startIcon={<Science/>}>Test this Eval</Button>
							<Button size="small" variant="contained" color="info"  disabled={editSection !== null} onClick={()=>{setShowReport(true)}} startIcon={<Assessment/>}>Sample Report</Button>
						</Stack>
					</Stack>
				</Alert>
				
			</Stack>}
			
			{schema && !schema.sections.length && <Box className="sub-title" pt={2} mb={2} textAlign="center">No sections have been added to this evaluation. Click "Add a Section" below to create the first section.</Box>}

			{schema && <SectionStepper sx={{mt:1, mb:1}} activeStep={showSection} nonLinear alternativeLabel orientation="horizontal" >
				{schema.sections.map((s:any,i:number)=>{
					return <Step key={i} >
						<StepButton onClick={()=>setShowSection(i)}>{s.title}</StepButton>
					</Step>
				})}
				<Step>
					<StepButton onClick={(e)=>setEditEl(e.currentTarget)} sx={{'& .MuiStepLabel-label': {color: theme.palette['primary'].main}}} icon={<AddCircleOutline color='primary'/>}>Add a Section</StepButton>
				</Step>
			</SectionStepper>}
			<Box position="relative" sx={{overflowX:'hidden', marginX: -2, px: 4, }} zIndex={100}  mt={2} minHeight={400}>
				<Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start" sx={{transition: 'transform 1s', transform: `translateX(calc(-${showSection}00% - ${showSection*16}px))`}}>
					{schema && schema.sections.sort((a,b)=>a.sequence-b.sequence).map((s:any,i:number)=>{
							return <Box minWidth="100%" maxWidth="100%" key={i} pb={2}>
								<HoverCard className={`highlight editing ${showSection===i ? "active" : ""}`}>
									<Section section={s} schema={schema} editMode={true} index={i} editing={editSection==i} onCancel={()=>setEditSection(null)} onEditing={(j)=>setEditSection(i)} onUpdate={updateSection} onDelete={deleteSection} canEdit={i == showSection} invalid={{}} />
								</HoverCard>
							</Box>;
						})}
					
				</Stack>
			</Box>
			

			{/* <Stack spacing={4}>
				{schema && schema.sections.map((s: any, i: number) => {
					return <HoverCard key={i} sx={{position:"relative"}} className={editSection===i ? "highlight editing" : ""}>
						<Section key={i} section={s} schema={schema} editMode={true} editing={editSection==i} onCancel={()=>setEditSection(null)} onEditing={(id)=>setEditSection(i)} onUpdate={updateSection} onDelete={deleteSection} canEdit={!editSection} invalid={{}} sx={{opacity: editSection !== null && editSection != i ? 0.5 : 1}}/>
					</HoverCard>; 
				})}
				{editSection === null && <NewItemBox className="editable" sx={{p:3, mt:2}} onClick={(e)=>setEditEl(e.currentTarget)}><Divider  textAlign="center" variant="middle" >+ New Section</Divider></NewItemBox>}
			</Stack>			 */}
		</Stack>
		
		<Notification open={!!editEl} anchorEl={editEl} onClose={()=>setEditEl(null)} minWidth={400} maxWidth={600} background="#fff" color="#000" over={true} ContainerProps={{p:0, overflow: 'hidden'}}>
			<form onSubmit={createSection}>
				<Box className="card-header">Add a New Section</Box>
				<Stack p={2} spacing={2}>
					<Box><TextField fullWidth size="small" autoFocus label="New Section Name" value={newSectionName} onChange={(e)=>setNewSectionName(e.currentTarget.value)}/></Box>
					<Stack direction="row" spacing={1} justifyContent="flex-end">
						<Button size="small" variant="outlined" color="primary" onClick={()=>setEditEl(null)}>Cancel</Button>
						<Button size="small" variant="contained" type="submit" color="primary" >Create Section</Button>
					</Stack>
				</Stack>
			</form>
		</Notification>

				

		<BackdropBox className={editSection !== null ? "show" : ""} onClick={()=>setConfirm(null)}></BackdropBox>
		
		</>;
};

const createSampleData = function(schema, responses) {
	var data = {
		count: responses,
		sections: []
	};
	data.sections = schema.sections.map((s: any, i: number) => {
		var section = {title: s.title, sequence: s.sequence, questions: Array<any>(), ratings: []};
		let questions = Array<any>();
		s.blocks.map((b: any, j: number) => {
			
			if (b.type != "likert") {
				questions = [...questions, ...b.questions.map((q: any, k: number) => {
					var question = {
						block_id: b.id,
						question_id: q.id,
						label: q.label,
						block_label: b.label,
						likert_labels: b.likert_labels || "Poor|Needs Attention|Average|Good|Excellent",
						prompt: q.prompt,
						type: q.type,
						answers: [...Array(responses)].map(a=>{return {answer:generateAnswer(q.type, q.choices)}}),
					};

					if (q.type == "number") {
						question = {...question, ...generateNumberStats(question)};
					}
					return question;
				})];
			} else {
				questions = [...questions, {
						block_id: b.id,
						label: b.label,
						block_label: b.label,
						likert_labels: b.likert_labels || "Poor|Needs Attention|Average|Good|Excellent",
						prompt: b.prompt,
						type: "likert",
						entries: responses,
						ratings: generateRatings(b, responses)
				}];
			}
			
		});
		section.questions = [...section.questions, ...questions];	
		return section;
	});
	return data; 
}

function generateNumberStats(question) {	
	
	let g = Array.from({length: 100}, (v, k) => Math.floor(Math.random()*20))
	let p = Array.from({length: 100}, (v, k) => Math.floor(Math.random()*20))
	let stats = {
		global_mean: mean(g),
		global_std: standardDeviation(g),
		program_mean:  mean(p),
		program_std: standardDeviation(p)
	};
	
	return stats;
}

function generateAnswer(type, choices) {
	
	if (type == "radio") {
		return choices[Math.floor(Math.random()*choices.length)].code;
	} else if (type == "multi-select") {
		var opts = choices.map(c=>c.code);
		var num = Math.floor(Math.random()*opts.length);
		let selected = Array<any>();
		for (let i=0; i<num; i++) {
			var idx = Math.floor(Math.random()*opts.length);
			var x = opts.splice(idx, 1);
			selected.push(x);
		}		
		return selected.join('|');

	} else if (type == "yes_no") {
		return Math.floor(Math.random()*10) % 2 ? "yes" : "no";
	} else if (type == "multiline") {
		const lorem = new LoremIpsum({
			
			sentencesPerParagraph: {
			  max: 4,
			  min: 2
			},
			wordsPerSentence: {
			  max: 16,
			  min: 4
			}
		  }, 'plain');		  
		return lorem.generateParagraphs(Math.ceil(Math.random()*3));

	} else if (type == "text") {
		const lorem = new LoremIpsum({
			sentencesPerParagraph: {
			  max: 1,
			  min: 1
			},
			wordsPerSentence: {
			  max: 12,
			  min: 3
			}
		  }, 'plain');		  
		return lorem.generateParagraphs(1);
	} else if (type == "number") {
		return Math.floor(Math.random()*20);
	}
	return "";
	
}

function generateRatings(block, num : number) {
	let choices = block.likert_labels?.split('|').length || 5;
	return block.questions.map(q=>{

		let picks = {};
		let total = 0;
		for (let i=0; i<num; i++) {
			var pick = Math.floor(Math.random()*choices);
			total += pick+1;
			picks[pick] = (picks[pick] || 0)+1;
		}

		return {
			block: block.block_id,
			block_label: block.label, 
			question_id: q.question_id,
			prompt: q.prompt,
			sequence: q.sequence,
			label: q.label,
			entries: num,
			program_entries: num*10,
			global_entries: num*100,
			course_total: total,
			program_total: Math.floor(Math.random()*choices)*num*10,
			global_total: Math.floor(Math.random()*choices)*num*100,
			course: total/num,
			program: Math.floor(Math.random()*choices),
			global: Math.floor(Math.random()*choices),
			ratings: picks
		}
	});

	
	
}

export default EvalEditor;