import { Box, FormControl, FormControlLabel, InputLabel, Stack, Switch, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const Registration = (props : any) => {

	const {onSettingChange, tenantInfo, show} = props;
	
	const {reset, control, watch, setValue, register, formState } = useForm({
		defaultValues: {
			"REG_INTRO": tenantInfo.settings["REG_INTRO"],
			"REG_REQ_EMAIL_VER": tenantInfo.settings["REG_REQ_EMAIL_VER"] == "true",
			"REG_RESTRICT_DOMAINS": tenantInfo.settings["REG_RESTRICT_DOMAINS"] == "true",
			"REG_EMAIL_DOMAINS": tenantInfo.settings["REG_EMAIL_DOMAINS"],
			"REG_INC_PHONE": tenantInfo.settings["REG_INC_PHONE"],
			"REG_INC_OU": tenantInfo.settings["REG_INC_OU"],
			"REG_FIELDS": tenantInfo.settings["REG_FIELDS"],
		}
	});

	var wEmailVer = watch("REG_REQ_EMAIL_VER");
	var wRestrictDomains = watch("REG_RESTRICT_DOMAINS");

	const fieldChange = (values: any, change: any) => {
		if (change.name) {
			onSettingChange(change.name, change.values[change.name]);
		}
	}
	
	useEffect(()=>{
		reset({
			"REG_INTRO": tenantInfo.settings["REG_INTRO"],
			"REG_REQ_EMAIL_VER": [true,"true",1,"1"].includes(tenantInfo.settings["REG_REQ_EMAIL_VER"]),
			"REG_RESTRICT_DOMAINS": [true,"true",1,"1"].includes(tenantInfo.settings["REG_RESTRICT_DOMAINS"]),
			"REG_EMAIL_DOMAINS": tenantInfo.settings["REG_EMAIL_DOMAINS"],
			"REG_INC_PHONE": tenantInfo.settings["REG_INC_PHONE"],
			"REG_INC_OU": tenantInfo.settings["REG_INC_OU"],
			"REG_FIELDS": tenantInfo.settings["REG_FIELDS"],
		});
	}, [tenantInfo]);

	useEffect(() => {
		var unwatch = watch((data,{name, type}) => {
			if (name) {
				onSettingChange(name, data[name]);
			}
		});
		return () => {
			unwatch.unsubscribe();
		};
	}, []);

	useEffect(() =>{
		if (!wEmailVer && !!wRestrictDomains) {
			setValue("REG_RESTRICT_DOMAINS", false);
		}

	}, [wEmailVer]);

	return <Box display={show ? '' : 'none'}>
		
		<form >
			<Stack spacing={2} maxWidth={600}>
				<FormControl fullWidth size="small" >
					<Controller name="REG_INTRO" control={control} render={({field}) => <TextField {...field} multiline rows="3" size="small" label="Registration Message / Instructions"/>} /> 
				</FormControl>
				
				<Box pl={1}>
					<FormControl fullWidth size="small" >
						<Controller name="REG_REQ_EMAIL_VER" control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value} size="small"/>} label={`Require users to verify their email addresses`}/>}  /> 
					</FormControl>
				</Box>

				<Box pl={1}>
					<FormControl fullWidth disabled={!wEmailVer} size="small"  >
						<Controller name="REG_RESTRICT_DOMAINS" control={control} render={({ field: { onChange, value } }) => <FormControlLabel control={<Switch onChange={onChange} checked={value} size="small"/>} label={`Restrict registration emails to specific domains`}/>}  /> 
					</FormControl>
				</Box>

				<FormControl fullWidth size="small">
					<Controller name="REG_EMAIL_DOMAINS"  disabled={!(wEmailVer && wRestrictDomains)} control={control} render={({field}) => <TextField {...field} multiline rows="3" size="small"  label="Permitted email domains (one per line)"/>} /> 
				</FormControl>
			</Stack>
		</form>
	</Box>;
};

export default Registration;