import { createTheme, lighten, darken, getInitColorSchemeScript, palette} from '@mui/material/styles';


import './themeEx.tsx';

const theme = createTheme({
  palette: {
    tonalOffset: 0.25, 
  }
});

export const getTheme = (tenant) => {
  return createTheme(theme,{
    palette: {
      mode: 'light',
      tonalOffset: 0.25, 
      contrastThreshold: 3,
      primary: {...theme.palette.augmentColor({ color:{main:tenant.settings.COLOR_PRIMARY}}), ultraLight: lighten(tenant.settings.COLOR_PRIMARY, 0.85), lighter: lighten(tenant.settings.COLOR_PRIMARY, 0.7), pale: lighten(tenant.settings.COLOR_PRIMARY, 0.9)},
      // primary: theme.palette.augmentColor({ color:{main:'#607D8B'}}),
      error: theme.palette.augmentColor({ color:{main:'#d32f2f', pale: '#FFCDD2'}}), //
      secondary: theme.palette.augmentColor({ color:{main:tenant.settings.COLOR_SECONDARY}}),
      success: theme.palette.augmentColor({ color:{main:'#6c782e', pale: '#e6eedd'}}),
      info: theme.palette.augmentColor({ color:{main:'#607D8B', pale: tenant.settings.COLOR_INFO}}),
      warning: theme.palette.augmentColor({ color:{main:'#ed6c02', pale: '#FEE0B2'}}),
      white: {
        main:'#ffffff',
        dark:'#ffffff',
        light:'#ffffff',
        contrastText:'#000000'
      },
      black: { 
        main:'#000000',
        dark:'#000000',
        light:'#000000',
        contrastText:'#ffffff'
      },
      gold: theme.palette.augmentColor({ color:{main:'#d2c412', contrastText:'#FFFFFF'}}),
      disabled: { 
        main:'#777777', 
        dark:'#333333', 
        light:'#c0c0c0', 
        pale:'#f8f8f8', 
        contrastText:'#ffffff'
      },
      important: theme.palette.augmentColor({ color:{main:'#ffd54c'}})
    },
  });
}