import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { extend_user } from './access'


export const dataSlice = createSlice({
	name: 'data',
	initialState: {
		user: null,
		ranks: [],
		agencies: [],
		affiliations: [],
		roles: [],
		roleMap: {},
		unit_roles: [],
		courses : [],
		badges: [],
		tracks: [],
		certs: [],
		config: {},
		alerts: [],
		id_token: null,
		token_provider: null,
		refresh_timestamp: null,
		systemName: 'dev'
	},
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setCourses: (state, action) => {
			state.courses = action.payload;
		},
		setRanks: (state, action) => {
			state.ranks = action.payload;
		},
		setAgencies: (state, action) => {
			state.agencies = action.payload;
		},
		setAffiliations: (state, action) => {
			state.affiliations = action.payload;
		},	
		setBadges: (state, action) => {
			state.badges = action.payload;
		},
		setTracks: (state, action) => {
			state.tracks = action.payload;
		},
		setRoles: (state, action) => {
			state.roles = action.payload;
			state.roleMap = action.payload.reduce((memo,r) =>{
				memo[r.id] = r;
				return memo;
			}, {});
		},	
		setUnitRoles: (state, action) => {
			state.unit_roles = action.payload;
		},		
		setCerts: (state, action) => {
			state.certs = action.payload;
		},
		setToken: (state, action) => {
			state.id_token = action.payload.id_token;
			state.token_provider = action.payload.provider;
		},
		setConfig: (state, action) => {
			state.config = action.payload;
		},
		setSystemName: (state, action) => {
			state.system = action.payload;
		},
		refreshData: (state, action) => {
			state.refresh_timestamp = new Date().getTime();
		},
		updateLocalUser: (state, action) => {
			state.user = {...state.user, ...action.payload};
		},
		setAlerts: (state, action) => {
			state.alerts = action.payload;
		},
		signOut: (state, action) => {
			state.user = null;
			state.id_token = null;
			document.cookie = "id_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.valkyrietraining.org";
			document.cookie = "_x=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ";
			// window.location.reload();
		}
	}
})


export const { setUser, signOut, setToken, setAlerts, refreshData, roleMap, setRanks, setAffiliations, setAgencies, setRoles, setCourses, setBadges, setTracks, setCerts, setConfig, setSystemName, setUnitRoles, updateLocalUser } = dataSlice.actions;

export default dataSlice.reducer