import { Box, FormControl, InputLabel, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

const General: React.FC = (props: any) => {

	const {onSettingChange, tenantInfo, show} = props;
	const {reset, control, watch, setValue, register, formState: {errors} } = useForm({
		defaultValues: {
			"SITE_NAME": tenantInfo.settings["SITE_NAME"],
			"SITE_WELCOME": tenantInfo.settings["SITE_WELCOME"],
			"SITE_LOGO": tenantInfo.settings["SITE_LOGO"],
			"SITE_ICON": tenantInfo.settings["SITE_ICON"],
			"COLOR_PRIMARY": tenantInfo.settings["COLOR_PRIMARY"],
			"COLOR_SECONDARY": tenantInfo.settings["COLOR_SECONDARY"],
			"COLOR_INFO": tenantInfo.settings["COLOR_INFO"],
		}
	});

	const handleSubmit = (e: any) => {
		e.preventDefault();
	}

	

	useEffect(()=>{ 
		reset({
			"SITE_NAME": tenantInfo.settings["SITE_NAME"],
			"SITE_WELCOME": tenantInfo.settings["SITE_WELCOME"],
			"SITE_LOGO": tenantInfo.settings["SITE_LOGO"],
			"SITE_ICON": tenantInfo.settings["SITE_ICON"],
			"COLOR_PRIMARY": tenantInfo.settings["COLOR_PRIMARY"],
			"COLOR_SECONDARY": tenantInfo.settings["COLOR_SECONDARY"],
			"COLOR_INFO": tenantInfo.settings["COLOR_INFO"],
		});
	}, [tenantInfo]);

	useEffect(() => {
		
		var unwatch = watch((data,{name, type}) => {
			if (name) {
				onSettingChange(name, data[name]);
			}
		});
		return () => {
			unwatch.unsubscribe();
		};
	}, []);

	return <Box maxWidth={600} display={show ? '' : 'none'}>
		<form onSubmit={handleSubmit}>
			<Stack spacing={2}>
				<FormControl fullWidth size="small" >
					<Controller name="SITE_NAME" control={control} render={({field}) => <TextField {...field} label="Site Name" size="small"/>} /> 
				</FormControl>

				<FormControl fullWidth size="small" >
					<Controller name="SITE_WELCOME" control={control} render={({field}) => <TextField {...field} label="Welcome Message" multiline rows="3" size="small"/>} /> 
				</FormControl>
			</Stack>
		</form>
	</Box>;
};

export default General;