import { Box } from '@mui/material';
import React from 'react';

const Fields: React.FC = (props: any) => {
	const {show, tenantInfo} = props;
	return (
		<Box display={show ? '' : 'none'}>
			<Box className="sub-title" pb={2}>Custom fields allow for extraneous data to be collected, searched and maintained for various objects in the system.</Box>
		</Box>
	);
};

export default Fields;