import { InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';

const ColorInput = React.forwardRef(({onChange, value, label, ...other}, ref) => {
	

	const handleChange = (event) => {
		onChange(event.target.value);
	};

	return (
		<TextField ref={ref} prefix='#' label={label} {...other} placeholder="#FF0000 / Red"
			InputProps={{
				startAdornment: <InputAdornment position="start"><div style={{border: 'solid 1px #ccc', backgroundColor: value, borderRadius: '3px', width: '1rem', height: '1rem'}}/></InputAdornment>,
			}}
			value={value}
			onChange={handleChange} />
	);
});

export default ColorInput;