import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Route, Redirect, useParams } from "react-router-dom";
import { hasAnyRole, hasAnyUnitRole, hasRole } from "../features/access";
import { VerifyEmail } from "./VerifyEmail";
import { getAuth } from "firebase/auth";
import app from '../helper/firebase';

function Forbidden(props) {
	return <Box>
		<h1>Forbidden</h1>
		<p>You do not have permission to access this content.</p>
	</Box>
}

export default function Guard(props)  {
	const {roles, unitRoles, children, requireEmailVerification} = props;
	const user = useSelector((state) => state.data.user);

	const params = useParams();
	var auth = getAuth();

	// if (requireEmailVerification && !auth.currentUser?.emailVerified) {
	// 	return <VerifyEmail/>; 
	// }

	return (roles && hasAnyRole(user, roles)) || hasAnyUnitRole(user,unitRoles, params["unit_id"]) ? children : <Forbidden/>;
}