import React, { useEffect } from 'react';
import { Backdrop, Box, Button, CircularProgress, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Stack } from '@mui/material';
import { Add, Clear } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import jax from '../../helper/jax';

const ActionSet: React.FC = (props: any) => {
	const { onChange, steps, actionSetId, ...other } = props;
	const [addingAction, setAddingAction] = React.useState<Boolean>(false);
	const [newActionType, setNewActionType] = React.useState<String>("");
	const [newActionId, setNewActionId] = React.useState<String>("");
	const [actions, setActions] = React.useState<Array<any>>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	const { roles, certs, tracks, badges } = useSelector((state:any) => state.data);
	const { terms } = useSelector((state:any) => state.app);

	useEffect(() => {
		if (steps) {
			setActions(steps);
		} else if (actionSetId) {
			setLoading(true);
			jax.get(`/app/admin/actions/${actionSetId}`).then(data => {
				setActions(data);
				onChange(data);
				setLoading(false);
			});
		}
	}, [steps, actionSetId]);

	function getActionLabel(type) {
		switch (type) {
			case "CERTIFY": return `Award a ${terms["CERTIFICATION"].s}`;
			case "ADD_ROLE": return `Assign ${terms["USER"].s} to a new ${terms["ROLE"].s}`;
			case "REMOVE_ROLE": return `Remove a ${terms["ROLE"].s} from the ${terms["USER"].s}`;
			case "NEW_TRACK": return `Assign ${terms["USER"].s} to a new ${terms["TRACK"].s}`;
			case "ADD_BADGE": return `Award ${terms["USER"].s} ${terms["BADGE"].s}`;
			case "REMOVE_BADGE": return `Remove a completed ${terms["BADGE"].s} from the ${terms["USER"].s}`;
			case "REMOVE_BADGES": return `Remove all ${terms["USER"].s} ${terms["BADGE"].p} related to this ${terms["TRACK"].s}`;
			default: return type;
		}
	}

	function getObjectTypeLabel(type, id) {
		switch (type) {
			case "CERTIFY": return certs.find((c:any) => c.id === id)?.name;
			case "ADD_ROLE": return roles.find((r:any) => r.id === id)?.name;
			case "REMOVE_ROLE": return roles.find((r:any) => r.id === id)?.name;
			case "NEW_TRACK": return tracks.find((t:any) => t.id === id)?.name;
			case "ADD_BADGE": return badges.find((b:any) => b.id === id)?.name;
			case "REMOVE_BADGE": return badges.find((b:any) => b.id === id)?.name;
			default: return id;
		}
	}

	function addAction() {
		if (newActionType) {
			var temp = actions || [];
			temp.push({action_type: newActionType, object_id: newActionId, action_set_id: actionSetId, sequence: temp.length});
			temp.sort((a,b) => a.sequence - b.sequence);
			setActions(temp);
			setNewActionId("");
			setNewActionType("");
			setAddingAction(false);
			onChange(temp);
		}
	}

	function removeAction(i: number) {
		var temp = [...actions];
		
		var item = temp.filter((x,j)=>!x._remove)[i];
		if (item.id) {
			item._remove = true;
		} else {
			temp = temp.filter((a:any) => a !== item);
		}
		setActions(temp);
		onChange(temp);
	}

	return (
		<Stack {...other} spacing={0} flex={1.25} position='relative'>
			<Backdrop open={loading} color="white" sx={{position:'absolute', background: "#ffffff88", zIndex: 1000}} >
				{loading && <Box><CircularProgress/></Box>}
			</Backdrop>
			<List dense >
				{actions?.filter(x=>!x._remove).map((a:any, i) => {
					return <><ListItem key={i}>
						<ListItemText primary={getActionLabel(a.action_type)} secondary={getObjectTypeLabel(a.action_type, a.object_id)}/>
						<ListItemIcon>
							<IconButton size="small" onClick={()=>removeAction(i)}><Clear/></IconButton>
						</ListItemIcon>
					</ListItem>
					{i < actions?.filter(x=>!x._remove).length && <Divider/>}
					</>;
				})}
			</List>

			{!addingAction && <Box textAlign="center" p={1}>
				<Button size="small" variant="text" startIcon={<Add/>} onClick={()=>setAddingAction(true)}>Add an Action</Button>
			</Box>}
			
			{addingAction && <Box p={2}>
				<FormControl fullWidth size="small" >
					<InputLabel id="demo-simple-select-label">Action</InputLabel>
					<Select label="Action" size="small" value={newActionType} onChange={(e)=>setNewActionType(e.target?.value || "")} variant="outlined">
						<MenuItem value="">&nbsp;</MenuItem>
						<MenuItem value="CERTIFY" >{getActionLabel("CERTIFY")}</MenuItem>
						<MenuItem value="ADD_ROLE">{getActionLabel("ADD_ROLE")}</MenuItem>
						<MenuItem value="REMOVE_ROLE">{getActionLabel("REMOVE_ROLE")}</MenuItem>
						<MenuItem value="NEW_TRACK">{getActionLabel("NEW_TRACK")}</MenuItem>
						<MenuItem value="ADD_BADGE">{getActionLabel("ADD_BADGE")}</MenuItem>
						<MenuItem value="REMOVE_BADGE">{getActionLabel("REMOVE_BADGE")}</MenuItem>
						<MenuItem value="REMOVE_BADGES">{getActionLabel("REMOVE_BADGES")}</MenuItem>
					</Select>
				</FormControl>
				
				{newActionType == "CERTIFY" && <Box mt={2}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Award {terms["CERTIFICATION"].s}</InputLabel>
						<Select label={`Award ${terms["CERTIFICATION"].s}`}  size="small" variant="outlined" value={newActionId} onChange={(e)=>setNewActionId(e.target?.value || "")}>
							<MenuItem value="">&nbsp;</MenuItem>
							{certs.map((c,i) => <MenuItem key={i} value={c.id}>{c.name}</MenuItem>)}
						</Select>
					</FormControl>
				</Box>}

				{newActionType == "ADD_ROLE" && <Box mt={2}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Add {terms["ROLE"].s}</InputLabel>
						<Select label={`Add ${terms["ROLE"].s}`}  size="small" variant="outlined" value={newActionId} onChange={(e)=>setNewActionId(e.target?.value || "")}>
							<MenuItem value="">&nbsp;</MenuItem>
							{roles.filter(r=>!r.base_role).map((c,i) => <MenuItem key={i} value={c.id}>{c.name}</MenuItem>)}
						</Select>
					</FormControl>
				</Box>}

				{newActionType == "REMOVE_ROLE" && <Box mt={2}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Remove {terms["ROLE"].s}</InputLabel>
						<Select label={`Remove ${terms["ROLE"].s}`} size="small" variant="outlined" value={newActionId} onChange={(e)=>setNewActionId(e.target?.value || "")}>
							<MenuItem value="">&nbsp;</MenuItem>
							{roles.filter(r=>!r.base_role).map((c,i) => <MenuItem key={i} value={c.id}>{c.name}</MenuItem>)}
						</Select>
					</FormControl>
				</Box>}

				{newActionType == "NEW_TRACK" && <Box mt={2}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">New {terms["TRACK"].s}</InputLabel>
						<Select label={`Add ${terms["TRACK"].s}`}  size="small" variant="outlined" value={newActionId} onChange={(e)=>setNewActionId(e.target?.value || "")}>
							<MenuItem value="">&nbsp;</MenuItem>
							{tracks.map((c,i) => <MenuItem key={i} value={c.id}>{c.name}</MenuItem>)}
						</Select>
					</FormControl>
				</Box>}

				{newActionType == "ADD_BADGE" && <Box mt={2}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Add {terms["BADGE"].s}</InputLabel>
						<Select label={`Add ${terms["BADGE"].s}`}  size="small" variant="outlined" value={newActionId} onChange={(e)=>setNewActionId(e.target?.value || "")}>
							<MenuItem value="">&nbsp;</MenuItem>
							{badges.map((c,i) => <MenuItem key={i} value={c.id}>{c.name}</MenuItem>)}
						</Select>
					</FormControl>
				</Box>}

				{newActionType == "REMOVE_BADGE" && <Box mt={2}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Remove {terms["BADGE"].s}</InputLabel>
						<Select label={`Remove ${terms["BADGE"].s}`}  size="small" variant="outlined" value={newActionId} onChange={(e)=>setNewActionId(e.target?.value || "")}>
							<MenuItem value="">&nbsp;</MenuItem>
							{badges.map((c,i) => <MenuItem key={i} value={c.id}>{c.name}</MenuItem>)}
						</Select>
					</FormControl>
				</Box>}
				<Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
					<Button size="small" variant="text" color="disabled" onClick={()=>{setAddingAction(false); setNewActionType(""); setNewActionId("");}}>Cancel</Button>
					<Button size="small" variant="contained" onClick={addAction} disabled={!newActionType || (newActionType != "REMOVE_BADGES" && !newActionId)}>Add Action</Button>
				</Stack>
			</Box>}
		</Stack>
	);
};

export default ActionSet;