
import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, Divider, FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useTheme } from '@mui/material';
import { Add, Verified } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { setRoles } from '../features/data';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm, set } from 'react-hook-form';
import jax from '../helper/jax';

const Roles : React.FC = (props: any) => {

	const {show} = props;
	const theme = useTheme(); 
	const nav = useNavigate();
	const {roles} = useSelector((state: any) => state.data);
	const [editRole, setEditRole] = useState<any>(null);
	const [types, setTypes] = useState<any>([]);
	const dispatch = useDispatch();

	useEffect(() => {
		setTypes(roles.filter(x=>x.base_role === 1));
	}, []);

	const getRoleName = (id: number) => {
		let role = roles.find((r: any) => r.id === id);
		return role ? role.name : "";
	}

	const addNewRole = (role: any) => {
		dispatch(setRoles([...roles, role]));
	}

	const updateRole = (role: any) => {
		let newRoles = roles.map((r: any) => {
			return r.id === role.id ? role : r;
		});
		dispatch(setRoles(newRoles));
		
	}

	return <Stack spacing={2} display={show ? "" : "none"}>
		{types.map((t: any, i: number) => {
		return <Paper key={i} variant="outlined" >
			<Stack >
				<Box mt={1} pl={2} mb={1}><b>{t.name} Roles</b></Box>
				<Divider/>
				<TableContainer >
					<Table size="small">
						<TableHead sx={{background: theme.palette.disabled['pale']}}>
							<TableRow>
								<TableCell width="30%">Name</TableCell>
								<TableCell width="20%">Inherits From</TableCell>
								<TableCell width="15%" align='center'># Users</TableCell>
								<TableCell width="35%">Description</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{roles?.filter(r=>r.root_role == t.id && !r.base_role).map((r: any, i: number) => {
								return <TableRow key={i} hover={!r.base_role}  sx={{cursor: r.base_role ? '' : 'pointer'}} onClick={()=>!r.base_role && setEditRole(r)}>
									<TableCell >{r.name}</TableCell>
									<TableCell>{getRoleName(r.parent_role) || <Box className="sub-title">---</Box>}</TableCell>
									<TableCell align='center'>{r.users || 0}</TableCell>
									<TableCell><Box whiteSpace="nowrap" textOverflow="ellipsis" className="sub-title xs">{r.description}</Box></TableCell>
								</TableRow>
							})}
							<TableRow hover sx={{cursor: 'pointer', "&:hover": {backgroundColor: `${theme.palette.primary["pale"]} !important`}}} onClick={()=>setEditRole({parent_role: t.id})}>
								<TableCell colSpan={4} sx={{border:0}}>
									<Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={0.5} color={theme.palette.primary.main}>
										<Box lineHeight={0}><Add/></Box>
										<Box >New {t.name} Role</Box>
									</Stack>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					
				</TableContainer>
			</Stack>
		</Paper>;
		})}

		<NewRoleDialog editRole={editRole} onClose={()=>setEditRole(null)} onNewRole={addNewRole} onChange={updateRole}></NewRoleDialog>
	</Stack>;
}

const NewRoleDialog : React.FC<any> = (props: any) => {

	const {editRole, onClose, onNewRole, onChange} = props;

	const {roles, certs: allCerts} = useSelector((state: any) => state.data); 

	type RoleForm = {
		id: number | null,
		roleId: number | null,
		name: string,
		parent_role: string,
		description: string,
		verified_by: number[]
	}

	const { handleSubmit, reset, control, watch, setValue, register, formState: {errors} } = useForm<RoleForm>({
		defaultValues: {
			id: null,
			roleId: null,
			name: "",
			parent_role: "",
			description: "",
			verified_by: [],
		}
	});
	const [showMenuEl, setShowMenuEl] = useState<any>(null);
	
	var wParentRole = watch("parent_role");
	var certs = watch("verified_by") || [];

	useEffect(() => {
		if (editRole) {
			reset({
				id: editRole.id || null,
				name: editRole.name || "",
				parent_role: editRole.parent_role || "",
				description: editRole.description || "",
				verified_by: editRole.verified_by || [],
			});
		}
	}, [editRole]);

	const submit = (data: any) => {
		let isNew = !editRole.id;
		jax.post('/app/admin/roles', data).then((res) => {
			if(isNew && onNewRole) onNewRole(res);
			if(!isNew && onChange) onChange(res);
			onClose();
		});
	}

	const addCert = (id: any) => {
		if (certs.includes(id)) return;
		setValue("verified_by", [...certs, id]);
		setShowMenuEl(null);
	}

	return <Dialog open={!!editRole} onClose={onClose} fullWidth maxWidth="xs" PaperProps={{
		component: 'form',
		onSubmit: handleSubmit(submit)
	  }}>
		<Box className="card-header">
			{!editRole?.id ? "Create a new user role" : `Edit ${editRole.name} role`}
		</Box>
		<Stack spacing={2} p={2} pt={3}>
			<Controller name="parent_role" control={control} rules={{required: "Required"}} render={({field}) => {
				return <FormControl size="small" >
					<InputLabel id="role_label">Role Type</InputLabel>
					<Select {...field} size="small" label="Role Type" fullWidth>
						{roles.map((r,i)=>{
							return r.id != editRole?.id && <MenuItem key={i} value={r.id}>{r.name}</MenuItem>;
						})}
					</Select>
				</FormControl>;
			}}/>
			<Controller name="name" control={control} rules={{required: "Required"}} render={({field}) => {
				return <TextField {...field} size="small" label="Role Name" fullWidth error={!!errors.name} helperText={errors.name?.message}></TextField>
			}}/>
			<Controller name="description" control={control} render={({field}) => {
				return <TextField {...field} size="small" label="Description" multiline rows={2} fullWidth></TextField>
			}}/>

			{!!allCerts.length && <Box>
				<span className="sub-title">Users of this role can be verified by possessing one of the following certifications.</span>
				{!!certs.length && <Box pt={2}>
					{certs?.map((v: any, i: number) => {
						var cert = allCerts.find((c: any) => c.id === v);
						return cert && <Chip size="small" icon={<Verified/>} sx={{mr:1}} label={cert.name} onDelete={()=>setValue("verified_by", certs.filter((c: any) => c !== v))}/>;
					})}
					
				</Box>}
				<Box pt={1}>
					<Button size="small" startIcon={<Add/>} fullWidth={false} onClick={(e)=>setShowMenuEl(e.currentTarget)}>Add Certification</Button>
				</Box>
			</Box>}

			<Stack direction="row" justifyContent="space-between">
				<Button size="small" onClick={onClose}>Cancel</Button>
				<Button size="small" type="submit" variant="contained">{editRole?.id ? "Save Changes" : "Create Role"}</Button>
			</Stack>
		</Stack>
		
		<Menu anchorEl={showMenuEl} open={!!showMenuEl} onClose={()=>setShowMenuEl(null)}>
			{allCerts.map((c: any, i: number) => {
				return <MenuItem key={i} onClick={()=>addCert(c.id)}>{c.name}</MenuItem>
			})}
		</Menu>
	</Dialog>
}

export default Roles;