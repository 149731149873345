import { ArrowForwardIosSharp, Error } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { Badge } from "@mui/material";
import { useSelector } from "react-redux";
import { can } from "../features/access";

const FlexTab = function(props) {
	const {children, label, badge, badgeColor, badgeContent} = props;
	return <Box className="flex-tab">
		<Box>{children}</Box>
	</Box>
}

// const M = memo(function(p) {
	
// 	const rendered = useRef(p.isActive);
// 	if (p.isActive && !rendered.current) {
//         rendered.current = true;
//     }
// 	if (!rendered.current)
//         return null;
// 	return <Box>{p.children}</Box>;
// }, (prev, next) =>{
// 	return prev.watch.every((w, i)=>w === next.watch[i]);
// });

const M = function(p) {
	const watch = useRef(p.watch || []);
	const rendered = useRef(p.isActive);
	
	if (!watch.current.every((w, i)=>w === p.watch[i])) {
		rendered.current = false;
	}
	
	if (p.isActive && !rendered.current) {
        rendered.current = true;
    } 
	if (!rendered.current)
        return null;
	return <Box>{p.children}</Box>;
}

function FlexTabs(props) {
	const {children, onChange, disableGutters, noBorderRadius, value, ...other} = props;
	var isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const [section, setSection] = useState(0);
	const [mounted, setMounted] = useState(false);
	


	function accordionExpand(i, item, isActive, expand) {
		if (isActive) {
			if (onChange) {
				onChange('_x');
			} else {
				setSection(null);
			}
		} else {
			onChange ? onChange(item.props.value) : setSection(i)
		}
		
	}

	return <Box className={`flex-tabs ${isMobile ? 'accordion' : 'tabs'} `} {...other}>
		{!isMobile && <Tabs value={value !== null ? value : section} variant="scrollable" scrollButtons={false} onChange={(e,v)=>(onChange ? onChange(v) : setSection(v))}>
			{children.map((child, i)=>{ 
				// return child && <Tab key={i} label={<Badge badgeContent={<Error color="warning"/>} color="transparent">{child.props.label}</Badge>} value={child.props.value !== null ? child.props.value : i}></Tab>
				return child && <Tab key={i} label={child.props.label} value={child.props.value !== null ? child.props.value : i}></Tab>
			})}
		</Tabs>}
		<Divider></Divider>
		{children.map((child, i)=>{
			if (!child) return;
			var isActive = child.props.value !== null ? (value === child.props.value) : (section == i);
			return <Accordion key={i} disableGutters expanded={isActive || isMobile} square={!!noBorderRadius} elevation={!isMobile ? 0 : 2}  onChange={(e,ex)=>accordionExpand(i, child, isActive, ex)} >
				{isMobile && <AccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem', marginRight:0 }}/>} sx={{flexDirection: 'row-reverse', '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {transform: 'rotate(90deg)'}}} >
					<Box ml={1}>{child.props.label}</Box>
				</AccordionSummary>}
				<AccordionDetails sx={{p:0, "&:before": {height: 0}, "&>.MuiBox-root": {padding: "0 !important"}}}>
				
					{/* VERY STRANGE ISSUE HERE.  ADDED AN ONCHANGE HANDLE TO STOP EVENTS INSIDE OF THE TABS FROM "BUBBLING" UP THE DOCUMENT.  TEXTBOX ONCHANGE EVENTS WERE CAUSING THE TAB / ACCORDON EVENTS TO FIRE */}
					{<Box padding={2} sx={{display: ((child.props.value !== null && value === child.props.value) || (value === null && section == i)) ? '' : 'none'}} onChange={(e)=>e.stopPropagation()}>
						
						{/* <Box>{[tabs[child.props.value === undefined ? i : child.props.value]]}</Box> */}
						<M watch={child.props.watch || []} {...child.props} isActive={isActive || isMobile}>{child}</M>
						
					</Box>}
				</AccordionDetails>
			</Accordion>
		})}
	</Box>
}

FlexTabs.defaultProps = {
	value: null,
};

FlexTab.defaultProps = {
	value: null
}


export {FlexTabs, FlexTab};