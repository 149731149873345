import { useTheme } from '@emotion/react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


const Terms: React.FC = (props: any) => {
	const {tenantInfo, onTermChange, show} = props;
	const theme = useTheme();
	const [editTerm, setEditTerm] = React.useState<any>(null);
	const [editCase, setEditCase] = React.useState<any>(null);

	const edit = (term: string, type: string) => {
		setEditTerm(term);
		setEditCase(type);
	}

	const update = (value: string) => {
		onTermChange(editTerm, editCase, value);
	}
	
	const handleSubmit = (e: any) => {
		e.preventDefault();
		setEditTerm(null);
		setEditCase(null);
	}

	useEffect(() => {
		
	}, [tenantInfo]);

	return (
		<Box onClick={(e)=>{if (e.currentTarget == null) {setEditTerm(null);setEditCase(null)}}} display={show ? '' : 'none'}>
			<Box className="sub-title" pb={2}>Fine tune the terminology and vocabulary to best fit your organization.  For each term, please provide both a singular and plural form to be used throughout the sytem.</Box>
			<form onSubmit={handleSubmit}>
				<TableContainer component={Paper} variant="outlined">
					<Table size="small">
						<TableHead>
							<TableRow sx={{bgcolor:theme.palette["disabled"].pale}}>
								<TableCell>Term</TableCell>
								<TableCell>Singular</TableCell>
								<TableCell>Plural</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Object.keys(tenantInfo.terms).map((term, i) => {
								return <TableRow key={i}>
									<TableCell width="33.3%" sx={{bgcolor:theme.palette["disabled"].pale}}><b>{tenantInfo.terms[term].default}</b></TableCell>
									<TableCell width="33.3%" py={0} onClick={()=>edit(term, 's')} sx={{cursor:'pointer',"&:hover":{background:theme.palette["primary"].pale}}}>
										{editTerm == term && editCase == 's' ? <TextField sx={{marginLeft: '-7px'}} inputProps={{onBlur: ()=>{setEditCase(null); setEditTerm(null);}, sx: {padding: '1px 6px', fontSize: '0.875rem'}}} value={tenantInfo.terms[term].s} size="small" autoFocus  onChange={(e)=>{update(e.target.value);}}/> : tenantInfo.terms[term].s}
										
									</TableCell>
									<TableCell width="33.3%" py={0} onClick={()=>edit(term, 'p')} sx={{cursor:'pointer',"&:hover":{background:theme.palette["primary"].pale}}}>
										{editTerm == term && editCase == 'p' ? <TextField sx={{marginLeft: '-7px'}} inputProps={{onBlur: ()=>{setEditCase(null); setEditTerm(null);}, sx: {padding: '1px 6px', fontSize: '0.875rem'}}} value={tenantInfo.terms[term].p} size="small" autoFocus  onChange={(e)=>{update(e.target.value);}}/> : tenantInfo.terms[term].p}
										
									</TableCell>
								</TableRow>
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</form>
		</Box>
	);
};

export default Terms;