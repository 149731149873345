import { Box, Breadcrumbs, Button, Chip, Checkbox, FormControlLabel, ListItemText, Menu, MenuItem, Skeleton, Stack, Paper, Divider, FormControl, Select, InputLabel, TextField, IconButton, Accordion, AccordionSummary, AccordionDetails, Card, alpha, Typography, List, ListItem, ListItemButton, Switch, MenuList, Icon, ListItemAvatar, ListItemIcon, Link, OutlinedInput } from '@mui/material';
import React, { useEffect, useState } from 'react';
import jax from '../helper/jax';
import { useParams } from 'react-router-dom';
import { Add, ArrowBackIos, ArrowRight, Cancel, CheckCircle, ChevronRight, Delete, DragHandle, Edit, ToggleOff, ToggleOn } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import {NestedMenuItem} from 'mui-nested-menu';
import { styled, useTheme } from '@mui/material/styles';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { SortableItem, SortableList } from '../components/SortableList.tsx';
import Notification from '../components/Notification.js';
import { roles as roleTypes } from '../features/access.js';
import Grid2 from '@mui/material/Unstable_Grid2';
import { BadgeIcon, BadgeMenu } from './Tracks.tsx';
import ActionSet from './settings/ActionSet.tsx';



interface CourseTypeProps {
	// Define the props for your component here
}

const CourseType: React.FC<CourseTypeProps> = (props) => {
	const {id} = useParams();
	const [type, setType] = useState<any>();

	const [schemas, setSchemas] = useState<any>(null);
	const [evals, setEvals] = useState<any>({});
	const [modules, setModules] = useState<any>([]);
	const [evalId, setEvalId] = useState<number | null>(null);
	const [savedType, setSavedType] = useState<any | null>(null);
	const [hasChanges, setHasChanges] = useState<boolean>(false);
	const [editing, setEditing] = useState<boolean>(false);
	const [showRole, setShowRole] = useState<any>(null);
	const [roleIndex, setRoleIndex] = useState<number>(-1);
	const {roles, roleMap} = useSelector((state: any) => state.data);
	
	const theme = useTheme();

	useEffect(() => {
		if (id) {
			jax.get(`/app/admin/course_types/${id}`).then((res) => {
				setType(res || {});
			});

			jax.get('/app/evaluations?published=1').then((res) => {
				setEvals(res.evals || {});
			});

			jax.get('/app/admin/modules').then((res) => {
				setModules(res.modules || []);
			});
		}
	}, []);

	useEffect(() => {
		if (evalId) {
			setSchemas(evals[evalId].versions || {});
		}
	}, [evalId]);

	useEffect(() => {
		if (type && !savedType) {
			var cloned = cloneDeep(type);
			setSavedType(cloned);
		} else if (type && savedType) {
			var same = isEqual(type, savedType);
			console.log(same ? 'same' : 'different');
			setHasChanges(!same);
		}
	}, [type, savedType]);


	const toggleRoleInstructor = (role: any, can_instruct: boolean) => {
		
		if (role) {
			role.can_instruct = can_instruct;
			updateRole(role);
			// if (!role.remove) {
			// 	setShowRole(role);
			// }
		} 
	}

	const toggleEnrollmentDefault = (role: any, default_approval: boolean) => {
		
		if (role) {
			role.default_approval = default_approval || null;
			updateRole(role);
			// if (!role.remove) {
			// 	setShowRole(role);
			// }
		} 
	}

	const updateRole = (role : any) => {
		var roles = [...type.roles];
		
		if (roles[roleIndex]) {
			
			roles[roleIndex] = cloneDeep(role);
			
			setType({...type, roles: roles});
		}
	}

	const save = () => {
		jax.post(`/app/admin/course_types/${id}`, type).then((res) => {
			setType(res || {});
			setShowRole(res?.roles[roleIndex] || null);
			setSavedType(cloneDeep(res));
			setEditing(false);
		});
	}
	
	const selectRole =(index: number) => {
		// if (!type.roles.some((r: any) => r.role_id == id && !r.remove)){
		// 	toggleRole(id);
		// }
		let role = index !== -1 && type.roles[index];
		if (role) {
			setRoleIndex(index);
			setShowRole(role);
		} else {
			setRoleIndex(-1);
			setShowRole(null);
		}
	}

	const newRole = ()=> {
		var role = {name: 'New Role', id: null, evals: [], requirements:[], modules: [], outcomes: [], badges: []};
		type.roles = [...type.roles, role];
		setType({...type});
		selectRole(type.roles.length-1);
	}

	return <Box>

		<Stack direction="row" mb={0} px={2} py={1} flex={1} justifyContent="space-between" alignItems="center" sx={{position:'sticky', top:0, zIndex:1000, backgroundColor: !hasChanges ? theme.palette.background.paper : theme.palette.primary["pale"], borderBottom: `solid 1px ${theme.palette.divider}`}}>
			<Breadcrumbs separator={<Box className="sub-title">/</Box>}> 
				<RouterLink className="sub-title" color="text.secondary" to={`/admin/course_types`}>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<ArrowBackIos fontSize='inherit'/> 
						<Box>All Course Types</Box>
					</Stack>
				</RouterLink> 
			</Breadcrumbs>

			<Stack direction="row" spacing={2}>
				{(editing || hasChanges) && <Button size="small" variant="outlined" color="disabled" onClick={()=>{setType(cloneDeep(savedType)); setEditing(false); setHasChanges(false); selectRole(-1);}}>Cancel</Button>}
				{hasChanges && <Button size="small" variant="contained" color="primary" onClick={save}>Save Changes</Button>}
				{!hasChanges && <Button size="small" variant="contained" color="disabled" disabled>Saved</Button>}
			</Stack>
		</Stack>
		
		<Box flex={1} px={2}>
			
			<Box flex={1} py={2}>
				<Box flex={1}>
					{!editing && <Stack direction="row" alignItems="center" spacing={1}><h2 >{type ? type.name  : <Skeleton></Skeleton>}</h2><IconButton size="small" onClick={()=>setEditing(true)}><Edit/></IconButton></Stack>}
					{editing && <TextField name="name" size="small" label="Course Type Name" inputProps={{size:60}} defaultValue={type.name} onChange={(e)=>{setType({...type, name: e.currentTarget.value}); }}></TextField>}
				</Box>
				<Box pt={editing ? 2 : 0}>
					<Box className="sub-title">
						{!editing && type?.description}
						{editing && <TextField name="description" multiline rows={2} inputProps={{cols:60}} size="small" label="Description" onChange={(e)=>{type.description=e.currentTarget.value; setType({...type}); }} defaultValue={type.description}></TextField>}
					</Box>
				</Box>
			</Box>	
			
			<Divider/>
			<Stack direction="row" spacing={2} mt={1}>
				<Box pt={1} pb={1} flex={1}>
					<Card variant="outlined">
						<Box className="sub-title" p={2}>
							Allow the following role(s) to enroll in courses of this type.  Each role may be assigned one or more exit evaluations that may be required for course completion.
						</Box>
						<Box >
							<Divider/>
							<List dense> 
								{type?.roles?.map((role: any, i : number) => {
									if (role.remove) {
										return null;
									}

									return <ListItemButton  divider key={i} onClick={()=>selectRole(i)} sx={{pr: 0, fontSize:'0.9rem', "& .MuiButtonBase-root": {display:"none"}, "&:hover .MuiButtonBase-root": {display:"block"}}} selected={i == roleIndex}>
										<ListItem disableGutters secondaryAction={<IconButton size="small" sx={{opacity:0.5,lineHeight:0,"&:hover": {opacity:1}}}><Delete/></IconButton>}>{role.name}</ListItem>
									</ListItemButton>
								})}
							</List>
							<Box>
								<Button size="small" fullWidth variant="text" startIcon={<Add/>} onClick={()=>newRole()} >Add Course Role</Button>
							</Box>
						</Box>
					</Card>
				</Box>
				<Box flex={3} pt={1}>
					{showRole && <>
						<Stack px={1.5} direction="row" alignItems="center" justifyContent="flex-start" spacing={3} mb={2}>
							{/* <Typography variant="h6">{showRole.name}</Typography> */}
							
							<Box flex={1}>
								<FormControl fullWidth>
									<TextField size="small" fullWidth label="Role Name" value={showRole.name} onChange={(e)=>{showRole.name=e.currentTarget.value; updateRole(showRole);}}></TextField>
								</FormControl>
							</Box>
					
						</Stack>

						<Box flex={1} px={3} pb={2}>
							<FormControlLabel control={<Switch size='small' checked={showRole?.can_instruct} onChange={(e)=>toggleRoleInstructor(showRole, e.currentTarget.checked)} />}  label={`This is an instructor role in this course type`} />
						</Box>

						<Box flex={1} px={3} pb={2}>
							<FormControlLabel control={<Switch size='small' checked={showRole?.default_approval} onChange={(e)=>toggleEnrollmentDefault(showRole, e.currentTarget.checked)} />}  label={`Approve enrollment by default`} />
						</Box>
					</>}

					{showRole && <CourseRole role={showRole} onChange={updateRole} evals={evals} modules={modules}/>}

					{!showRole && <Box>
						Please select a role...
					</Box>}
					
					{/* <Stack spacing={2} mt={2} >
						{roles.filter(r=>!r.base_role).map((role: any, i : number) => {
							var existing = type?.roles?.find(r=>r.role_id==role.id && !r.remove);
							
							return <CourseRole key={i} selected={!!existing} role_id={role.id} role_name={role.name} role={!!existing && existing} onSelect={toggleRole} onChange={updateRole} evals={evals} modules={modules}/>
						})}
					</Stack> */}
				</Box>
			</Stack>
		</Box>
	</Box>;
};

const CourseRole = (props: any) => {
	const {role, role_name, selected, onSelect, evals, modules, onChange} = props;

	const [evalMenuEl, setEvalMenuEl] = useState<null | HTMLElement>(null);
	const [moduleMenuEl, setModuleMenuEl] = useState<null | HTMLElement>(null);
	const [schemaMenuEl, setSchemaMenuEl] = useState<null | HTMLElement>(null);
	const [shouldAdvance, setShouldAdvance] = useState<boolean>(!!role.completion_role_id);
	const [shouldCertify, setShouldCertify] = useState<boolean>(false);
	const [assignRole, setAssignRole] = useState<any>(role.completion_role_id || '');
	const [showNewOutcome, setShowNewOutcome] = useState<any>(null);
	const [addReqEl, setAddReqEl] = useState<any>(null);
	const [newOutcomeName, setNewOutcomeName] = useState<any>('');
	const [showOutcome, setShowOutcome] = useState<number | null>(null);
	const [awardCert, setAwardCert] = useState<any>('');
	const [newBadgeEl, setNewBadgeEl] = useState<any>(null);
	const {terms} = useSelector((state: any) => state.app);

	const {roles, roleMap, badges} = useSelector((state: any) => state.data);

	const req_types = ['role', 'track', 'cert', 'badge'];

	let theme = useTheme();
	
	const addRoleEval = (role_id: any, eval_type_id: number, schema_id: number, at_enrollment: any) => {
		
		if (role) {
			var evl = evals[eval_type_id];
			var schema = evl.versions.find((v: any) => v.id == schema_id);
			if (!role.evals) {
				role.evals = [];
			}
			//We are using a temp_id for new evals in case they are deleted before they are saved.
			const getMin = (a, b) => Math.min(a || 0, b || 0);
 			var min = role.evals.reduce((a,b)=>getMin(a.temp_id,b.temp_id), 0);
			role.evals.push({temp_id: min-1, schema_id: schema_id, name: `${evl.name} (V${schema.version})`, sequence: role.evals.length+1, at_enrollment: parseInt(at_enrollment?.toString())});
			role.evals.map((e: any, i: number) => {
				e.sequence = i+1;	
			});
			onChange(role);
		}
	};

	function addModule(module_id: any) {
		if (role) {
			var module = modules.find(m=>m.id==module_id);
			if (!role.modules) {
				role.modules = [];
			}

			//We are using a temp_id for new modules in case they are deleted before they are saved.
			const getMin = (a, b) => Math.min(a || 0, b || 0);
 			var min = role.modules.reduce((a,b)=>getMin(a.temp_id,b.temp_id), 0);
			role.modules.push({temp_id: min-1, module_id:module_id, name: module.name, sequence: role.modules.length+1});
			role.modules.map((e: any, i: number) => {
				e.sequence = i+1;	
			});
			onChange(role);
		}
	}

	const removeRoleEval = (id_or_temp_id: number) => {
		if (role) {
			role.evals = role.evals.filter((e: any) => e.id != id_or_temp_id && e.temp_id != id_or_temp_id);
			role.evals.map((e: any, i: number) => {
				e.sequence = i+1;	
			});
			onChange(role);
		}
	};

	const removeRoleModule = (id_or_temp_id: number | string) => {
		if (role) {
			role.modules = role.modules.filter((e: any) => e.id != id_or_temp_id && e.temp_id != id_or_temp_id);
			role.modules.map((e: any, i: number) => {
				e.sequence = i+1;	
			});
			onChange(role);
		}
	};

	function reorder_module(action) {
		let mods = role.modules.sort((a,b) => a.sequence < b.sequence);
		let [removed] = mods.splice(action.oldIndex, 1);
		mods.splice(action.newIndex, 0, removed);
		mods.map((e: any, i: number) => {
			e.sequence = i+1;
		});
		onChange(role);
	}

	function reorder_eval(action) {
		let evls = role.evals.sort((a,b) => a.sequence < b.sequence);
		let [removed] = evls.splice(action.oldIndex, 1);
		evls.splice(action.newIndex, 0, removed);
		evls.map((e: any, i: number) => {
			e.sequence = i+1;
		});
		onChange(role);
	}

	function createOutcome(e) {
		e.preventDefault();
		if (role) {
			role.outcomes.push({name: newOutcomeName, sequence: role.outcomes.length+1, role_id: role.id, new_role_id: null, award_cert: null, actions:[]});
			onChange(role);
			setShowNewOutcome(null);
			setNewOutcomeName('');
			setShowOutcome(role.outcomes.length-1);
		}
	}

	function addBadgeToRole(badge_id: any) {
		if (role) {
			role.badges.push(badge_id);
			onChange(role);
		}
	}

	useEffect(() => {
		if (role){
			setAssignRole(role.completion_role_id);
			setShouldAdvance(!!role.completion_role_id);
			setShouldCertify(!!role.certificate_id);
		}
	}, [role]);

	useEffect(() => {
		if (shouldAdvance) {
			if (!!assignRole && role.completion_role_id != assignRole) {
				role.completion_role_id = assignRole;
				onChange(role);
			}
		} else {
			if (!!role.completion_role_id) {
				role.completion_role_id = null;
				onChange(role);
			}
		}
		
	}, [assignRole, shouldAdvance]);

	const updateReq = (req: any, index: number) => {
		var reqs = role.requirements || [];
		
		reqs[index] = {...req};
		
		role.requirements = [...reqs];
		onChange(role);
	}

	const addReq = () => {
		var reqs = role.requirements || [];

		
		const type = req_types.find(t => !reqs.find(r => r.type == t));

		reqs.push({type: type, oper: 'ANY', values: []});
		role.requirements = [...reqs];
		onChange(role);
	}

	const deleteRequirement = (index: number) => {
		var reqs = role.requirements || [];
		reqs.splice(index, 1);
		role.requirements = [...reqs];
		onChange(role);
	}

	return <Stack spacing={4}>
			<Stack  alignItems="stretch">
				
				<Box position="relative" pt={1}>
					
				
					{role && <Stack direction="column" pl={1.5}  spacing={2} alignItems="stretch" justifyContent="space-evenly" >
						
						<Card variant="outlined">
							<Box flex={1} padding={1}>
								<b>Enrollment Requirements</b>
							</Box>
							<Divider/>
							<Stack flex={1} alignItems="stretch" spacing={1} padding={2}>
								<Box flex={1} >										
									To enroll as <b>{role.name}</b>, {terms["USER"].p.toLowerCase()} must meet ALL of the following requirement(s).
								</Box>
								{Object.keys(role.requirements || {}).length == 0 && <Box flex={1} fontStyle="italic" textAlign="center" pt={2} className="sub-title">
									Anyone can enroll as {role.name}.
								</Box>}
								
								<Grid2 container spacing={2} alignItems="center">
									{role.requirements?.map((req: any, i: number) => {
										return <Requirement key={i} index={i} {...req} exclude={role.requirements?.map(r=>r.type)} onChange={updateReq} onDelete={deleteRequirement}/>
									})}
								</Grid2>
								
								
							</Stack>
							{role.requirements?.length < req_types.length && <Box p={2} pt={0}>
								<Button size="small" variant="text" startIcon={<Add/>} onClick={addReq}>Add a Requirement</Button>
							</Box>}
						</Card>
						<Card variant="outlined">
							<Box flex={1} padding={1}>
								<b>Evaluations</b>
							</Box>
							<Divider/>
							<Stack flex={1} direction="row" justifyContent="space-evenly" alignItems="flex-start" spacing={0}>
								<Box flex={1} padding={2}>
									<Box height={60}>
										<Box sx={{fontSize:'0.9rem', fontWeight:500}}>Enrollment Evaluation(s)</Box>
										<Box className="sub-title" mt={1}>
											Present students of this role with the following evaluation(s) at the time they enroll.
										</Box>
									</Box>
									<Stack pt={1} mt={2} spacing={0.5} justifyContent="stretch">
										<Box mt={3} pb={1}>
											<SortableList onSortEnd={reorder_eval} allowDrag={true} allowDelete={true} useDragHandle lockAxis='y' onDelete={(id)=>removeRoleEval(id)}>
												{role.evals && role.evals?.filter(x=>!!x.at_enrollment).sort((a,b) => a.sequence < b.sequence).map((evl: any, j: number) => {
													return <SortableItem key={j} index={j} text={evl.name} id={evl.id || evl.temp_id}/>
												})}
											</SortableList>
										</Box>
										
										<Box data-role-id={role.id} data-at-enrollment={1}><Button color="primary" variant="text" startIcon={<Add/>} onClick={(e)=>{setEvalMenuEl(e.currentTarget)}} sx={{ ml:0}} size="small" data-role-id={role.id} data-at-enrollment={1} >Add Evaluation</Button></Box>
									</Stack>
									
								</Box>
								<Divider orientation="vertical" flexItem variant="middle"/>
								<Box flex={1} padding={2}>
									<Box height={60}>
										<Box sx={{fontSize:'0.9rem', fontWeight:500}}>Exit Evaluation(s)</Box>
										<Box className="sub-title" mt={1}>
											Present students of this role with the following evaluation(s) at the end of the course.
										</Box>
									</Box>
									<Stack pt={1} mt={2} spacing={0.5} justifyContent="stretch">
										<Box mt={3} pb={1}>
											<SortableList onSortEnd={reorder_eval} allowDrag={true} allowDelete={true} useDragHandle lockAxis='y' onDelete={(id)=>removeRoleEval(id)}>
												{role.evals && role.evals?.filter(x=>!x.at_enrollment).sort((a,b) => a.sequence < b.sequence).map((evl: any, j: number) => {
													return <SortableItem key={j} index={j} text={evl.name} id={evl.id || evl.temp_id}/>
												})}
											</SortableList>
										</Box>
										

										<Box data-role-id={role.id} data-at-enrollment={0}><Button color="primary" variant="text" startIcon={<Add/>} onClick={(e)=>{setEvalMenuEl(e.currentTarget)}} sx={{ ml:0}} size="small" data-role-id={role.id} data-at-enrollment={0} >Add Evaluation</Button></Box>

										
									</Stack>
								</Box>
								
												
								
							</Stack>
							<Divider/>
							<Box className="sub-title xs" p={2} fontStyle="italic">
								* If multiple evaluations are selected, they will be combined and presented as one seamless evaluation to the student.
							</Box>
						</Card>
						<Box flex={1} >
							<Card  variant='outlined' >
								
								<Box p={1}>
									<b>Training Module(s)</b>
								</Box>
								<Divider/>
								<Box p={2} >
									<Box className="sub-title" mb={2}>
										Select which module(s) will be available to participants during this course.
									</Box>
									<Box pb={1} width="50%">
										<SortableList onSortEnd={reorder_module}  allowDrag={true} allowDelete={true} useDragHandle lockAxis='y' onDelete={removeRoleModule}>
											{role.modules && role.modules?.sort((a,b) => a.sequence < b.sequence).map((mod: any, j: number) => {
												return <SortableItem key={j} index={j} text={mod.name} id={mod.id || mod.temp_id}/>
											})}
											
										</SortableList>
									</Box>

									<Box data-role-id={role.id} ><Button color="primary" variant="text" startIcon={<Add/>} onClick={(e)=>{setModuleMenuEl(e.currentTarget)}} sx={{ ml:0}} size="small" data-role-id={role.id}  >Add Module</Button></Box>

								</Box>
							</Card>
						</Box>
						<Box >
							<Card  variant='outlined' >
								<Box p={1}>
									<b>Partipant Outcomes</b>
								</Box>
								<Divider/>
								<Box p={2} >
									<Box className="sub-title" my={1} pb={1} color="black !important">
										At the completion of each course, each <b>{role.name}</b> will be assigned one of the following outcomes based on their performance.
									</Box>

									<Card  variant="outlined" sx={{borderColor: alpha(theme.palette.primary.main,0.5), mt:1}}>
										{role.outcomes?.map((o: any, i: number) => {
											return <React.Fragment key={i}>
												<OutcomeAccordion key={i} expanded={showOutcome == i} onChange={()=>showOutcome == i ? setShowOutcome(null) : setShowOutcome(i)}>
													<AccordionSummary expandIcon={<ChevronRight color="primary"/>} >
													<Box borderTop={`solid 1px ${alpha(theme.palette.primary.main,0.5)}`}></Box>
														<Stack direction="row" flex={1} alignItems="center" >
															<Box flex={1}>{o.name}</Box>
															<Box pr={1} lineHeight={0}>
																{o.pass === 1 && <CheckCircle fontSize="small" color="success"/>}
																{o.pass === -1 && <Cancel fontSize="small" color="error"/>} 
															</Box>

														</Stack>
													</AccordionSummary>
													<Divider></Divider>
													<AccordionDetails >
													
														<RoleOutcome role={role} roles={roles} outcome={o} onChange={(role)=>onChange(role)}/>
													</AccordionDetails>

												</OutcomeAccordion>
												
											</React.Fragment>
										})}
										<Box>
										
										<Notification open={!!showNewOutcome} anchorEl={showNewOutcome} onClose={()=>setShowNewOutcome(null)} minWidth={400} maxWidth={600} background="#fff" color="#000" over={true} ContainerProps={{p:0, overflow: 'hidden'}}>
											<form onSubmit={createOutcome}>
												<Box className="card-header">Add an Outcome</Box>
												<Stack p={2} spacing={2}>
													<Box><TextField fullWidth size="small" autoFocus label="New Outcome Name" value={newOutcomeName} onChange={(e)=>setNewOutcomeName(e.currentTarget.value)}/></Box>
													<Stack direction="row" spacing={1} justifyContent="flex-end">
														<Button size="small" variant="outlined" color="primary" onClick={()=>setShowNewOutcome(null)}>Cancel</Button>
														<Button size="small" variant="contained" type="submit" color="primary" >Create Outcome</Button>
													</Stack>
												</Stack>
											</form>
										</Notification>
										</Box>
									</Card>
									<Button  color="primary" variant="text" startIcon={<Add/>} onClick={(e)=>{setShowNewOutcome(e.currentTarget)}} sx={{ ml:0, my:"-1px"}} size="medium" data-role-id={role.id} data-at-enrollment={1} >Add Outcome</Button>
								</Box>
							</Card>
						</Box>
						<Box pb={2}>
							<Card  variant='outlined' >
								<Box p={1}>
									<b>Available {terms["BADGE"].p}</b>
								</Box>
								<Divider/>
								<Box p={2} pb={0}>
									<Box className="sub-title" my={1} pb={1} color="black !important">
										Specify {terms["BADGE"].p} that may be awarded to a <b>{role.name}</b> at the conclusion of the course.
									</Box>
								</Box>
								<Box p={2} pt={0}>
									<Grid2 container spacing={2} >
										{role?.badges?.map((b,i) =>{
											var badge = badges.find((bd: any) => bd.id == b);
											return <Grid2 xs={2} md={2} lg={2} key={i}>
												<Stack spacing={1} alignItems="center" flex={1}>
													<BadgeIcon size="large" icon={badge.icon} color={badge.color} tooltip={badge.description} ></BadgeIcon>
													<Box textAlign="center" className="sub-title xs">{badge.name}</Box>
												</Stack>
											</Grid2>
										})}
										<Grid2 xs={2} md={2} lg={2} >
											<Stack spacing={1} alignItems="center" flex={1}>
												<BadgeIcon size="large" isNew tooltip={`Add a new ${terms["BADGE"].s}`} onClick={(e)=>setNewBadgeEl(e.currentTarget)}></BadgeIcon>
												<Box textAlign="center" className="sub-title xs">New {terms["BADGE"].s}</Box>
											</Stack>
										</Grid2>
									</Grid2>
								</Box>
							</Card>
						</Box>
						<BadgeMenu el={newBadgeEl} onClose={()=>setNewBadgeEl(null)} onSelect={addBadgeToRole} badgeIds={badges.map(b=>b.id)}/>
						
					</Stack>}
				</Box>
			</Stack>

			<Menu
				id="eval-menu"
				anchorEl={evalMenuEl}
				open={!!evalMenuEl}
				anchorPosition={{top: 0, left: 0}}
				anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
				onClose={()=>{setEvalMenuEl(null)}}>
					{Object.keys(evals || {}).map((k: any, i: number) => {
						var e = evals[k];

						return <NestedMenuItem key={i} parentMenuOpen={!!evalMenuEl} label={e.name} sx={{alignItems:'center'}} >
							{e.versions?.map((s: any, j: number) => {
								return <MenuItem key={j} onClick={()=>{ addRoleEval(evalMenuEl?.getAttribute('data-role-id'), e.id, s.id, evalMenuEl?.getAttribute('data-at-enrollment')); setEvalMenuEl(null);}}>
									Version {s.version}
								</MenuItem>
							})}
						</NestedMenuItem>;
					})}
			</Menu>

			<Menu
				id="module-menu"
				anchorEl={moduleMenuEl}
				open={!!moduleMenuEl}
				transformOrigin={{horizontal: "right", vertical: "top"}}
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				onClose={()=>{setModuleMenuEl(null)}}>
					{modules.map((m: any, i: number) => {
						return <MenuItem key={i} onClick={()=>{ addModule(m.id); setModuleMenuEl(null);}}>
							{m.name}
						</MenuItem>
						

						// return <MenuItem onClick={()=>{}} onMouseEnter={(ev)=>{if (e.id != evalId) { setSchemaMenuEl(ev.currentTarget); setEvalId(e.id);}}} sx={{alignItems:'center'}} data-eval-id={e.id} >
						// 	<ListItemText>{e.name}</ListItemText>
						// 	<Box ml={2} lineHeight={0}><ArrowRight/></Box>
						// </MenuItem>
					})}
			</Menu>
		</Stack>;
}

const Requirement = (props: any) => {

	const {type, oper, values, index, onChange, exclude, onDelete} = props;

	const {terms} = useSelector((state: any) => state.app);
	const [label, setLabel] = useState<string>('');
	const [options, setOptions] = useState<any[]>([]);
	const {roles, badges, tracks, certs} = useSelector((state: any) => state.data);

	const [selected, setSelected] = useState<any[]>(values);

	const change = (update) => {
		if (update.type) {
			setSelected([]);
		}
		onChange({type: type, oper: oper, values: selected, ...update}, index);
	}

	useEffect(() => {
		
		switch (type) {
			case 'role':
				setLabel(`${terms["ROLE"].p}`);
				break;
			case 'badge':
				setLabel(`${terms["BADGE"].p}`);
				break;
			case 'track':
				setLabel(`${terms["TRACK"].p}`);
				break;
			case 'track_complete':
				setLabel(`${terms["TRACK"].p}`);
				break;
			case 'cert':
				setLabel(`${terms["CERTIFICATION"].p}`);
				break;
			default:
				setLabel('Requirement');
				break;
		}
		if (type == 'role') {
			setOptions(roles.filter(r=>!r.base_role).map(r=>({id: r.id, name: r.name})));
		} else if (type == 'badge') {
			setOptions(badges.map(r=>({id: r.id, name: r.name})));
		} else if (type == 'track') {
			setOptions(tracks.map(r=>({id: r.id, name: r.name})));
		} else if (type == 'cert') {
			setOptions(certs.map(r=>({id: r.id, name: r.name})));
		}
	}, [type]);

	useEffect(() => {
		setSelected(values);
	}, [values]);

	const renderValues = (selected: any) => {
		if (selected?.length > 3) {
			return `${selected.length} selected`;
		}
		if (type == 'role') {
			return selected.map((s: any) => roles.find(r=>r.id==s)?.name).join(', ');
		} else if (type == 'badge') {
			return selected.map((s: any) => badges.find(r=>r.id==s)?.name).join(', ');
		} else if (type == 'track' || type == 'track_complete') {
			return selected.map((s: any) => tracks.find(r=>r.id==s)?.name).join(', ');
		} else if (type == 'cert') {
			return selected.map((s: any) => certs.find(r=>r.id==s)?.name).join(', ');
		}
	}

	return <>
		<Grid2 xs={3} >
			<FormControl size="small" fullWidth >
				<InputLabel>Requirement</InputLabel>
				<Select label="Requirement" value={type} onChange={(e)=>{change({type: e.target.value})}}>
					{(type == "role" || !exclude?.includes('role')) && <MenuItem value="role">Has a {terms["ROLE"].s}</MenuItem>}
					{(type == "badge" || !exclude?.includes('badge')) && <MenuItem value="badge">Completed an {terms["BADGE"].s}</MenuItem>}
					{(type == "track" || !exclude?.includes('track')) && <MenuItem value="track">Is on a {terms["TRACK"].s}</MenuItem>}
					{(type == "track_complete" || !exclude?.includes('track_complete')) && <MenuItem value="track_complete">Has Completed a {terms["TRACK"].s}</MenuItem>}
					{(type == "cert" || !exclude?.includes('cert')) && <MenuItem value="cert">Has a {terms["CERTIFICATION"].s}</MenuItem>}
				</Select>
			</FormControl>
		</Grid2>
		<Grid2 xs={2}>
			<FormControl size="small" fullWidth >
				<InputLabel>Include</InputLabel>
				<Select label="Include" value={oper} onChange={(e)=>{change({oper: e.target.value})}}>
					<MenuItem value="ANY">any of...</MenuItem>
					<MenuItem value="ALL">all of...</MenuItem>
				</Select>
			</FormControl>
		</Grid2>
		<Grid2 xs={6} position="relative">
			<FormControl size="small" fullWidth  >
				<InputLabel>{label}</InputLabel>
				<Select label={label} multiple value={selected} onChange={(e)=>{setSelected(e.target.value); change({values: e.target.value})}} renderValue={renderValues} input={<OutlinedInput label={label} />}> 
				{options.map((opt, i) => (
            		<MenuItem key={i} value={opt.id}>
						<Checkbox checked={selected.indexOf(opt.id) > -1} sx={{padding:0, mr:1}}/>
						<ListItemText primary={opt.name} />
					</MenuItem>
				))}
				</Select>
			</FormControl>
		</Grid2>
		<Grid2 xs={1} alignItems="align" >
			<IconButton size="small" sx={{opacity:0.5, "&:hover":{opacity:1}}} onClick={()=>onDelete(index)}><Delete/></IconButton>
		</Grid2>
	</>;
}

const RoleOutcome = (props: any) => {

	const {role, outcome, roles, onChange} = props;
	const [shouldAdvance, setShouldAdvance] = useState<boolean>(!!outcome.new_role_id);
	const [shouldCertify, setShouldCertify] = useState<boolean>(!!outcome.certificate_id);
	const {certs} = useSelector((state: any) => state.data);

	useEffect(() => {
		if (!shouldAdvance) {
			outcome.new_role_id = null;
			onChange(role);
		}
	}, [shouldAdvance]);

	useEffect(() => {
		if (!shouldCertify) {
			outcome.certificate_id = null;
			onChange(role);
		}
	}, [shouldCertify]);

	let outcomes = {
		1: {
			label:"Successful Completion",
			description: "The user has successfully completed the course."
		},
		"-1": {
			label: "Failure to Complete",
			description: "The user failed to complete the course.  This outcome will be recorded in their training history."
		},
		0: {
			label: "None / Incomplete",
			description: "This user participated in the course, but did not pass or fail."
		}
	}

	return <>
		<Stack spacing={2} >
			<Box className="sub-title" pb={1} color="black !important">If the "{outcome.name}" outcome is selected, the user will be affected in the following ways.</Box>
			<Box mb={2} width="50%">
				<FormControl size="small" fullWidth >
					<InputLabel>Equivalence</InputLabel>
					<Select label="Equivalence" placeholder='None / Incomplete' value={outcome.pass} onChange={(e)=>{outcome.pass = e.target.value; onChange(role)}} renderValue={(v)=>outcomes[v]?.label}>
						{Object.keys(outcomes).map((k: any, i: number) => {
							return <MenuItem key={i} value={k}><Stack >
								<Box><b>{outcomes[k].label}</b></Box>
								<Box className="sub-title xs" whiteSpace="wrap" maxWidth="300px">{outcomes[k].description}</Box>
							</Stack></MenuItem>
						})}
					</Select>
				</FormControl>
			</Box>
			<Box>
				<Card variant='outlined' >
					<Box  p={1} pb={0}><b>Outcome Actions</b></Box>
					<Box className="sub-title" p={1}>If this outcome is selected for a course participant, the following actions will also be taken on their profile.</Box>
					<Divider/>
					<ActionSet steps={outcome.actions || []} onChange={(steps: any) => {outcome.actions = [...steps]; onChange(role)}}/>
				</Card>
			</Box>
			{ false && <Stack direction="row" spacing={2} flex={1}>
				<Box flex={1}>
					<FormControlLabel disabled={outcome.pass < 1} control={<Checkbox checked={shouldAdvance} onChange={(e)=>setShouldAdvance(e.target.checked)}/>} label="Assign the user a new Role"/>
					{shouldAdvance && <Box px={4} flex={1} mb={2}>
						<FormControl size="small" fullWidth>
							<InputLabel >New Role</InputLabel>
							<Select label="New Role" defaultValue={outcome.new_role_id || ""} disabled={outcome.pass < 1} onChange={(e)=>{outcome.new_role_id = e.target.value; onChange(role)}}>
								{roles.filter(r=>!r.base_role).map((r: any, i: number) => {
									return <MenuItem key={i} value={r.id}>{r.name}</MenuItem>
								})}
							</Select>
							
						</FormControl>
					</Box>}
					
					<FormControlLabel disabled={outcome.pass < 1} control={<Checkbox checked={shouldCertify} onChange={(e)=>setShouldCertify(e.target.checked)}/>} label="Award a Certification"/>
					{shouldCertify && <Box px={4} flex={1} mb={2}>
						<FormControl size="small" fullWidth>
							<InputLabel disabled={outcome.pass < 1}>Certification</InputLabel>
							<Select label="Certification" defaultValue={outcome.certificate_id || ""} disabled={outcome.pass < 1} onChange={(e)=>{outcome.certificate_id=e.target.value; onChange(role)}}>
								{certs.map((c: any, i: number) => {
									return <MenuItem key={i} value={c.id}>{c.name}</MenuItem>
								})}
							</Select>
						</FormControl>
					</Box>}
				</Box>
				<Box flex={1}>
					{shouldAdvance && outcome.new_role_id && <FormControlLabel disabled={outcome.pass < 1} control={<Checkbox disabled={!shouldAdvance || !outcome.new_role_id || outcome.pass < 1} checked={outcome.replace_role} onChange={(e)=>{outcome.replace_role=e.currentTarget.checked; onChange(role)}}/>} label={`Remove ${role.name} Role`}/>}
					
				</Box>
			</Stack>}
		</Stack>
		
	</>
}

const OutcomeAccordion = styled(Accordion)(({ theme }) => ({
	
	'&.MuiAccordion-root': {
		boxShadow: 'none',
		border: `none`,
		'&.Mui-expanded': {
			margin: 0,
		}
	},
	'.MuiAccordionSummary-content': {
		margin: '0 !important',
		paddingTop: '5px',
		paddingBottom: '5px',
		lineHeight: 1.75
	},
	'.MuiAccordionSummary-root': {
		padding: 0,
		flexDirection: 'row-reverse',
		borderBottom: `none`,
		minHeight: '0 !important',
		fontSize: '0.875rem',
		fontWeight: 600,
		textTransform: 'uppercase',
		color: theme.palette.primary.main,
		'&.Mui-expanded': {
			minHeight: 0,
		}

	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
}));

export default CourseType;