
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Fab, Stack, styled } from '@mui/material';
import React, { memo } from 'react';
 
const CarouselWrap = styled(Box)((props) => ({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    left: 0,
	'& ul': {
		listStyleType: 'none',
  		padding: 0
	},
	'& .carousel__inner': {
		
		position: 'relative',
		width: '100%' //'`calc((${props.slideWidth}+${props.spacing}) * ${props.slideCnt})`,
	},
	'& .carousel__container': {
		height: '100%',
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
	},
	'& .carousel__slide-list': {
		height: '100%',
		left: 0,
		listStyleType: 'none',
		margin: '0',
		padding: '0',
		//position: 'absolute',
		transition: 'translate 1s',
		transform: `translateX(calc((${props.slideWidth} + ${props.spacing}) * -${props.activeInx}))`,
		width: `calc((${props.slideCnt} + 0.5) * ${props.slideWidth} * 2)`,
	},
	'& .carousel__slide-item': {
		display: 'inline-block',
		
		margin: '0',
		marginBottom: '4px',
		marginRight: props.spacing,
		transition: 'all 0.3s',
		width: props.slideWidth,
		'& > div': {
			height: '100%',
			width: '100%',
			'& > *': {
				height: '100%',
				width: '100%',
			}
		},
	}
})); 

const CarouselSlide = ({pos, idx, slideCnt, activeIdx, children, slideWidth}) => {
	
	const [style, setStyle] = React.useState({});

	React.useEffect(() => {
		setStyle(getStyle(pos, idx));
	}, [pos, idx]);

	const getStyle = (position, idx) => {
		var styles = {
				//transform: `translateX(calc(${position} * ${slideWidth}))`,
		};
	
		switch (position) {
			case slideCnt - 1:
			case slideCnt + 1:
				styles = {...styles, filter: 'grayscale(1)'};
				break;
			case slideCnt:
				break;
			default:
				styles = {...styles};
				break;
		}
	
		return styles;
	};

    return (
        <li className="carousel__slide-item" style={style}>
            
				<div >{children}</div>
			
        </li>
    );
};

const Carousel = (props) => {
	const {children, slideWidth, spacing='24px', ...rest} = props;
    
	const keys = Array.from(Array(children.length).keys());
	const [items, setItems] = React.useState(keys);
    const [isTicking, setIsTicking] = React.useState(false);
    const [activeIdx, setActiveIdx] = React.useState(0);
    const bigLength = children.length;

	

	const sleep = (ms = 0) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

    const prevClick = (jump = 1) => {
        if (!isTicking) {
            setIsTicking(true);
			setActiveIdx((activeIdx - 1));
        }
    };

    const nextClick = (jump = 1) => {
		
        if (!isTicking) {
            setIsTicking(true);
			setActiveIdx((activeIdx + 1));
          
        }
    };

    React.useEffect(() => {
        if (isTicking) sleep(300).then(() => setIsTicking(false));
    }, [isTicking]);

	var slideRef = React.useRef(null);
	React.useEffect(() => {
		slideRef.current.style.transition = 'all 0.5s';
		slideRef.current.style.transform = `translateX(calc(${activeIdx} * ${slideWidth}))`;
	}, [activeIdx]);
    return (
        <CarouselWrap slideWidth={slideWidth} slideCnt={children.length} activeInx={activeIdx} spacing={spacing} {...rest}>
            <div className="carousel__inner">
			
                <div className="carousel__container">
                    <Stack direction="row" alignItems="stretch" className="carousel__slide-list" ref={slideRef}>
						{items.map((pos, i) => (
                            <CarouselSlide	
                                key={i}
                                idx={i}
                                activeIdx={activeIdx}
								slideWidth={slideWidth}
								slideCnt={children.length}
								spacing={spacing}
                            >
								{children[i]}
							</CarouselSlide>
                        ))}
                    </Stack>
                </div>
				
				{activeIdx < 0 && <Fab color="primary" size="small" onClick={() => nextClick()} sx={{position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)'}}>
					<ChevronLeft />
				</Fab>}

				{activeIdx > -children.length-1 && <Fab color="primary" size="small" onClick={() => prevClick()} sx={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}>
					<ChevronRight />
				</Fab>}
				
				
            </div>
        </CarouselWrap>
    );
};

export default Carousel;
export { CarouselSlide };